import React from "react"
import { Typography, Box, Grow } from "@mui/material"
import { useRandomPartyParrot } from "../DataHooks/useRandomPartyParrot"

interface ToolHeaderProps {
    children: React.ReactNode
}

let ToolHeader = (props: ToolHeaderProps) => {
    const parrotURL = useRandomPartyParrot()

    return (
        <Grow in timeout={800}>
        <Box justifyContent={"center"} display="flex" mb="2rem">
            <Typography
                variant="h3"
                sx={{
                    fontWeight: "bold",
                    transition: "color 0.5s ease-in-out",
                    ":hover": {
                        color: "rgba(0, 0, 0, 0.2)",
                        backgroundImage: `url(${parrotURL})`,
                        backgroundPosition: "50% bottom",
                        backgroundRepeat: "repeat-x",
                        backgroundSize: "auto 100%",
                        backgroundOrigin: "content-box",
                        backgroundClip: "text",
                    }
                }}
            >
                {props.children}
            </Typography>
        </Box>
        </Grow>
    )
}

export default ToolHeader
