import { Box } from "@mui/material"
import React from "react"
import { Alert } from "./useAlerts"

interface AlertListProps {
    alerts: Alert[]
}

let AlertList = (props: AlertListProps) => {
    let { alerts } = props

    return (
        <Box marginBottom="0.5rem">
            {alerts.map((alert) => (
                <Box key={alert.key}>
                    {alert.element}
                </Box>
            ))}
        </Box>
    )
}

export default AlertList
