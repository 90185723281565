import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Table,
    TableBody,
} from "@mui/material"
import { Check, Close, Person } from "@mui/icons-material"
import _ from "lodash"
import React from "react"
import CloseableAlert from "../../PageStructure/AlertManager/CloseableAlert"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import { getColorForAffiliation, getIconForAffiliation } from "../../Utilities/accountUtilities"
import { AlertProps } from "../../PageStructure/AlertManager/useAlerts"
import useLDAP from "../../DataHooks/useLDAP"

interface BasicUserInfoProps {
    ucinetid: string
    expanded?: boolean
}

let BasicUserInfo = (props: BasicUserInfoProps & AlertProps) => {
    let { ucinetid, expanded = false, addAlert } = props

    let { loading, entry } = useLDAP(ucinetid)

    let {
        eduPersonPrincipalNamePrior,
        createTimestamp,
        displayName,
        myDotName,
        uciAffiliation,
        uciCampusID,
        uciMailDeliveryPoint,
        ucinetidLocked,
        ucinetidLockedAt,
        ucinetidPasswordChangedAt,
        ucinetidReset,
        ucinetidResetAt,
        uciPublishFlag,
        uciTestAccount,
        uciUCNetID,
        uid,
    } = entry?.attributes ?? {}

    let format_date_string = (date_string: string | undefined) => {
        if (date_string !== undefined) {
            let date = new Date(date_string)

            return date.toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                weekday: "long",
            })
        }
        return date_string
    }

    // Alerts
    React.useEffect(() => {
        if (ucinetidReset === "true") {
            addAlert(
                "BASIC USER INFO - UCInetID Reset",
                <CloseableAlert severity="warning" title="UCInetID Is Reset!">
                    The UCInetID is Reset. This usually means that the UCInetID needs to be
                    activated at activate.uci.edu
                </CloseableAlert>
            )
        }
    }, [ucinetidReset, addAlert])

    React.useEffect(() => {
        if (ucinetidLocked === "true") {
            addAlert(
                "BASIC USER INFO - UCInetID Locked",
                <CloseableAlert severity="error" title="UCInetID Is Locked!">
                    This is EXTREMELY UNUSUAL! UCInetIDs are rarely locked! You are dealing with a
                    special situation here!
                </CloseableAlert>
            )
        }
    }, [ucinetidLocked, addAlert])

    return (
        <InfoBlock title="User Info" icon={<Person></Person>} loading={loading} expanded={expanded}>
            <Paper>
                <Table>
                    <TableBody>
                        <TableKeyValueRow title="UCInetID">{uid}</TableKeyValueRow>
                        {eduPersonPrincipalNamePrior && eduPersonPrincipalNamePrior.length > 0 && (
                            <TableKeyValueRow
                                title="Previous UCInetID(s)"
                                tooltip="This is rare, but if a user has ever changed their UCInetID we'll list them here."
                            >
                                {eduPersonPrincipalNamePrior.join(", ")}
                            </TableKeyValueRow>
                        )}
                        <TableKeyValueRow title="Name">{displayName}</TableKeyValueRow>
                        <TableKeyValueRow
                            title="My.Name Email"
                            tooltip="Graduate Students and Employees can get a my.name email alias which they can use in addition to their default UCI email."
                        >
                            {myDotName ? myDotName : "No my.name Email Registered"}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="Mail Delivery Point"
                            tooltip="This is where emails sent to their UCI email (UCInetID@uci.edu) actually get forwarded. Users may be able to update their MDP themselves at uci.edu/phupdate."
                        >
                            {uciMailDeliveryPoint}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="Affiliations"
                            tooltip="Every UCInetID at UCI has internal affiliations (this is how quite a few applications control access). This is a list of the affiliations connected to the user's UCInetID."
                        >
                            <List dense>
                                {uciAffiliation?.map((aff) => (
                                    <ListItem key={aff} disableGutters>
                                        <ListItemAvatar>
                                            <Avatar
                                                style={{
                                                    backgroundColor: getColorForAffiliation(aff),
                                                }}
                                            >
                                                {getIconForAffiliation(aff)}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            {_.capitalize(_.replace(aff, "_", " "))}
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="Campus ID"
                            tooltip="UCInetIDs are not completely unique and can be reassigned to different people over time. Campus IDs are the solution to this issue. Every individual at UCI will have a unique Campus ID which can be used to identify them."
                        >
                            {uciCampusID}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="UCNetID"
                            tooltip="This likely won't be useful, but there may be extremely rare occasions where this helps. This is NOT their UCInetID but their UCnetID. This is a value provided by UCOP."
                        >
                            {uciUCNetID}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="LDAP Entry Created (Updated) On"
                            tooltip="This is the date and time that the LDAP entry was created. This does not necessarily correlate to when the UCInetID was created."
                        >
                            {format_date_string(createTimestamp)}
                        </TableKeyValueRow>
                        <TableKeyValueRow title="Password Changed On">
                            {format_date_string(ucinetidPasswordChangedAt)}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="Information Can Be Released?"
                            tooltip="This is false if the user is a former student or has a FERPA block. True in all other scenarios."
                        >
                            {uciPublishFlag === "true" ? <Check></Check> : <Close></Close>}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="Is UCInetID Reset?"
                            tooltip="This is true if the UCInetID is in a state where it can be reactivated. If it is reset, we'll put the time it was reset at below."
                        >
                            {ucinetidReset === "true" ? <Check></Check> : <Close></Close>}
                        </TableKeyValueRow>
                        {ucinetidReset === "true" && (
                            <TableKeyValueRow title="UCInetID Reset On">
                                {format_date_string(ucinetidResetAt)}
                            </TableKeyValueRow>
                        )}
                        <TableKeyValueRow title="Is Test Account?">
                            {uciTestAccount === "true" ? <Check></Check> : <Close></Close>}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="Is Account Locked?"
                            tooltip="This is NOT whether the user is experiencing a lockout from too many failed login attempts (these lockouts only last 1 minute, they can wait). UCInetID locks disable but do not delete the account. They are very rare."
                        >
                            {ucinetidLocked === "true" ? <Check></Check> : <Close></Close>}
                        </TableKeyValueRow>
                        {ucinetidLocked === "true" && (
                            <TableKeyValueRow title="Account Locked At">
                                {format_date_string(ucinetidLockedAt)}
                            </TableKeyValueRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </InfoBlock>
    )
}

export default BasicUserInfo
