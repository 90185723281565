import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    TextField,
    LinearProgress,
    Typography,
    Box,
} from "@mui/material"
import { green, red } from "@mui/material/colors"
import { Autocomplete } from '@mui/material';
import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { KFSOrg, SupportGroup } from "../../apiInterfaces"
import { axios } from "../../axios"

interface DSEditDialogProps {
    open: boolean
    setOpen: (value: boolean) => void
    org_dict: { [key: string]: KFSOrg }
    support_groups?: SupportGroup[]
    org?: string
}

export const DSEditDialog = (props: DSEditDialogProps) => {
    let { open, setOpen, org_dict, org, support_groups } = props

    let [supportGroup, setSupportGroup] = React.useState<SupportGroup | null>(null)

    const queryClient = useQueryClient()
    let mutation = useMutation(
        ["department-support-update"],
        (changed_support_groups: SupportGroup[]) =>
            // new Promise((r) => {
            //     console.log(changed_support_groups)
            //     r(null)
            // }),
            axios.post("/api/v1/department-support/groups", changed_support_groups),
        {
            onSuccess: (result: any) => {
                console.log(result)
                queryClient.invalidateQueries("support-groups", {exact: true})
            },
        }
    )

    let department_support_groups = React.useMemo(() => {
        // Map department numbers to support groups
        if (support_groups) {
            let department_support_groups: { [key: string]: string } = {}
            for (let sg of support_groups) {
                for (let dept of sg.supported_department_numbers) {
                    department_support_groups[dept] = sg.name
                }
            }
            return department_support_groups
        }
    }, [support_groups])

    let support_group_map = React.useMemo(() => {
        // Map support group names to support groups
        if (support_groups) {
            let support_group_map: { [key: string]: SupportGroup } = {}
            for (let sg of support_groups) {
                support_group_map[sg.name] = sg
            }
            return support_group_map
        }
    }, [support_groups])

    React.useEffect(() => {
        if (org !== undefined && department_support_groups !== undefined) {
            if (org in department_support_groups) {
                let sg_name = department_support_groups[org]
                setSupportGroup(support_group_map?.[sg_name] ?? null)
            } else {
                setSupportGroup(null)
            }
        }
    }, [org, department_support_groups, support_group_map])

    // console.log(org, support_group_map, department_support_groups, supportGroup)

    const handleSave = () => {
        let changes_made = false
        let changed_support_groups = []

        if (org && support_groups && department_support_groups) {
            // If the support group is null, remove the org from all support groups
            if (supportGroup === null) {
                for (let i = 0; i < support_groups.length; i++) {
                    let sg = support_groups[i]
                    let new_supported_department_numbers = sg.supported_department_numbers.filter(
                        (o) => o !== org
                    )

                    if (
                        new_supported_department_numbers.length !==
                        sg.supported_department_numbers.length
                    ) {
                        sg.supported_department_numbers = new_supported_department_numbers
                        changed_support_groups.push(sg)
                        changes_made = true
                    }
                }
            } else {
                // If the support group is the same, do nothing
                if (org in department_support_groups) {
                    if (department_support_groups[org] === supportGroup.name) {
                        // Do nothing
                        return
                    }
                }

                // If this org is supported by another group, remove it
                if (org in department_support_groups) {
                    let sg = support_group_map![department_support_groups[org]]

                    let new_supported_department_numbers = sg.supported_department_numbers.filter(
                        (o) => o !== org
                    )

                    if (
                        new_supported_department_numbers.length !==
                        sg.supported_department_numbers.length
                    ) {
                        let new_sg: SupportGroup = {
                            ...sg,
                            supported_department_numbers: new_supported_department_numbers,
                        }
                        changed_support_groups.push(new_sg)
                        changes_made = true
                    }
                }

                // Add this department number to the selected support group
                if (!(org in supportGroup.supported_department_numbers)) {
                    let new_sg = {
                        ...supportGroup,
                        supported_department_numbers: [
                            ...new Set([...supportGroup.supported_department_numbers, org]),
                        ],
                    }
                    changed_support_groups.push(new_sg)
                    changes_made = true
                }
            }

            // console.log(changes_made, changed_support_groups)
            if (changes_made) {
                mutation.mutate(changed_support_groups)
                console.log(support_groups)
            }
        }
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                Edit Support for {org !== undefined ? org_dict[org]?.org_name : "NO NAME"} ({org})
            </DialogTitle>
            <DialogContent>
                <Autocomplete
                    options={support_groups ?? []}
                    isOptionEqualToValue = {(o, v)=> o.name === v.name}
                    getOptionLabel={(sg) => sg.name}
                    value={supportGroup}
                    onChange={(event, newValue) => {
                        setSupportGroup(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Support Group" autoFocus></TextField>
                    )}
                ></Autocomplete>
                {mutation.isSuccess && (
                    <Box width="100%" p="1rem" color={green[500]}>
                        <Typography align="center">
                            <b>Update Success!</b>
                        </Typography>
                    </Box>
                )}
                {mutation.isError && (
                    <Box width="100%" p="1rem" color={red[500]}>
                        <Typography align="center">
                            <b>Update Failed!</b>
                        </Typography>
                        <Typography align="center">Reload the page and try again.</Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
            {mutation.isLoading && <LinearProgress></LinearProgress>}
        </Dialog>
    )
}
