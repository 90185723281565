import { Paper, Table, TableBody, TableContainer } from "@mui/material"
import { Dns } from "@mui/icons-material"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import { useQuery } from "react-query"
import { fetchNSLookupInfo } from "../../../apiFunctions"

interface NSLookupInfoProps {
    host: string
}

let NSLookupInfo = (props: NSLookupInfoProps) => {
    let { host } = props

    let { isLoading, data } = useQuery(
        ["nslookup", host],
        fetchNSLookupInfo(host)
    )

    return (
        <InfoBlock
            title="NSLookup"
            icon={<Dns></Dns>}
            loading={isLoading}
            hide={!isLoading && !data}
        >
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="Primary Host Name">
                                {data?.host_name}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Aliases">
                                {data?.aliases.join(", ")}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="IP Addresses">
                                {data?.ip_addresses.join(", ")}
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default NSLookupInfo
