import { Tooltip } from "@mui/material"
import { HelpOutline } from "@mui/icons-material"

interface HelpTooltipProps {
    tooltip: React.ReactNode | string
}

let HelpTooltip = (props: HelpTooltipProps) => {
    let { tooltip } = props

    return (
        <Tooltip title={tooltip ?? ""}>
            <HelpOutline
                fontSize="small"
                style={{ verticalAlign: "sub" }}
            ></HelpOutline>
        </Tooltip>
    )
}

export default HelpTooltip
