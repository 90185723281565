import { Typography, Grid, Box, Button, Fade } from "@mui/material"
import PageParent from "../PageStructure/PageParent/PageParent"
import { useCurrentUserInfo } from "../DataHooks/useCurrentUserInfo"

let HomePage = () => {
    let user = useCurrentUserInfo()
    let hour = new Date().getHours()
    let welcomeMessage: string
    console.log(hour)
    if (hour >= 6 && hour < 12) {
        welcomeMessage = "Good Morning"
    } else if (hour >= 12 && hour < 17) {
        welcomeMessage = "Good Afternoon"
    } else if (hour >= 17 && hour < 21) {
        welcomeMessage = "Good Evening"
    } else {
        welcomeMessage = "Sweet Dreams"
    }

    return (
        <PageParent backgroundOverlay={false}>
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    <Fade in={true} style={{ transitionDuration: "2s"}}>
                        <Typography variant="h4" pb="2rem" fontFamily="Permanent Marker">
                            {welcomeMessage + ", " + user.ldap_data?.givenName}
                        </Typography>
                    </Fade>
                    <Typography variant="body1" fontWeight="Medium">
                        Help Desk Utilities is a tool designed to provide easy access to data that
                        can help you solve incidents more effectively. You can also access the
                        changelog or documentation by clicking the buttons below. Please give us
                        your feedback on the application so we can make it work better for you!
                    </Typography>
                    <Box paddingTop="2rem">
                        <Grid container spacing={10} justifyContent="center">
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    href="https://teams.microsoft.com/l/channel/19%3a545e7460015d4899b0347b3e0846b9de%40thread.skype/HDUtils%2520Change%2520Log?groupId=5f0e8382-ccdc-424f-9923-afbbb0c4d30d&tenantId=68d4533c-eae6-4833-99cc-7a9704ac870b"
                                    target="_blank"
                                >
                                    Change Log
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    href="https://www.notion.so/Help-Desk-Utilities-Documentation-4bfce9dd7ddc4b44b646fea572dd4efb"
                                    target="_blank"
                                >
                                    Documentation
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </PageParent>
    )
}

export default HomePage
