import { Paper, Table, TableRow, TableCell, TableBody } from "@mui/material"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import { Security } from "@mui/icons-material"
import useLDAP from "../../DataHooks/useLDAP"

interface KSAMSInfoProps {
    ucinetid: string
    expanded?: boolean
}

let KSAMSInfo = (props: KSAMSInfoProps) => {
    let { ucinetid, expanded = false } = props

    let { loading, entry } = useLDAP(ucinetid)

    let ldap_attributes = entry?.attributes

    let ksams_permissions = ldap_attributes?.memberOf ?? []
    ksams_permissions = ksams_permissions.map((per) => {
        let i1 = per.indexOf("cn=")
        let i2 = per.indexOf(",ou=")

        if (i1 === -1 || i2 === -1) {
            return per
        } else {
            return per.slice(i1 + 3, i2)
        }
    })

    let hide = entry?.dn !== undefined && ksams_permissions.length === 0

    return (
        <InfoBlock
            title="KSAMS Info"
            icon={<Security></Security>}
            loading={loading}
            expanded={expanded}
            hide={hide}
        >
            <Paper>
                <Table>
                    <TableBody>
                        {ksams_permissions.map((per) => (
                            <TableRow key={per}>
                                <TableCell>{per}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </InfoBlock>
    )
}

export default KSAMSInfo
