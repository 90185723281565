import {
    amber,
    blue,
    blueGrey,
    cyan,
    deepOrange,
    deepPurple,
    green,
    indigo,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from "@mui/material/colors"
import {
    Book,
    CollectionsBookmark,
    ContactMail,
    EmojiPeople,
    FlightTakeoff,
    Group,
    Money,
    School,
    Work,
    Business,
    AccountBox,
    BusinessCenter,
    OutdoorGrill,
    Help,
    SettingsInputAntenna,
} from "@mui/icons-material"
import React from "react"
import { Affiliation, AffiliationOrder } from "../../apiInterfaces"

export const getIconForAffiliation = (affiliation: Affiliation) => {
    switch (affiliation) {
        case "applicant":
            return <ContactMail></ContactMail>
        case "student":
            return <School></School>
        case "alumni":
            return <FlightTakeoff></FlightTakeoff>
        case "advanced_degree_holder":
            return <CollectionsBookmark></CollectionsBookmark>
        case "degree_holder":
            return <Book></Book>
        case "employee":
            return <Business></Business>
        case "faculty":
            return <School></School>
        case "former_student":
            return <EmojiPeople></EmojiPeople>
        case "group":
            return <Group></Group>
        case "service_account":
            return <SettingsInputAntenna></SettingsInputAntenna>
        case "guest":
            return <AccountBox></AccountBox>
        case "staff":
            return <BusinessCenter></BusinessCenter>
        case "retiree":
            return <OutdoorGrill></OutdoorGrill>
        case "tpid (third-party id)":
            return <Money></Money>
        case "contingent_worker":
            return <Work></Work>
        default:
            return <Help></Help>
    }
}

export const getColorForAffiliation = (affiliation: Affiliation) => {
    switch (affiliation) {
        case "applicant":
            return blue[500]
        case "student":
            return cyan[500]
        case "former_student":
            return indigo[500]
        case "degree_holder":
            return purple[500]
        case "advanced_degree_holder":
            return deepPurple[500]
        case "alumni":
            return teal[500]

        case "staff":
            return yellow[500]
        case "contingent_worker":
            return amber[500]
        case "employee":
            return orange[500]
        case "faculty":
            return deepOrange[500]
        case "retiree":
            return red[500]

        case "group":
            return green[500]
        case "service_account":
            return pink[500]
        case "guest":
            return lime[500]
        case "tpid (third-party id)":
            return lightGreen[500]

        default:
            return blueGrey[500]
    }
}

export const getMainAffiliation = (affiliations?: Affiliation[]) => {
    if (affiliations === undefined) {
        return "guest"
    }

    let affiliation_set = new Set(affiliations)

    for (let affiliation of AffiliationOrder) {
        if (affiliation_set.has(affiliation)) {
            return affiliation
        }
    }
    return "guest"
}
