import {
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    TableContainer,
} from "@mui/material"
import { FileCopy, Help, ListAlt, Wifi } from "@mui/icons-material"
import { Skeleton } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { NetworkRegistration } from "../../../apiInterfaces"
import ButtonLinks from "../../GenericBlocks/ButtonLinks/ButtonLinks"
import HelpTooltip from "../../GenericBlocks/HelpTooltip"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import { format_date_string } from "../../Utilities/dateUtilities"

const URL_MOBILE_ACCESS_WIKI = "https://uci.atlassian.net/wiki/spaces/OITHD/pages/14746302/Blocks+and+Disconnects+Troubleshooting"
const URL_MOBILE_ACCESS_ADMIN = "https://mobileaccess.oit.uci.edu/admin/mac/"

interface NetworkRegistrationsProps {
    loading: boolean
    expanded?: boolean
    registrations: NetworkRegistration[]
}

let NetworkRegistrations = (props: NetworkRegistrationsProps) => {
    let { loading, expanded = false, registrations } = props

    console.log(registrations)

    let add_mac_separators = (mac_address: string) => {
        let groups = mac_address.match(/../g)
        return groups !== null ? groups.join(":") : mac_address
    }

    let copy_mac_to_clipboard = (mac_address: string) => () => {
        console.log(mac_address)
        let m = add_mac_separators(mac_address.toUpperCase())
        console.log(m)
        navigator.clipboard
            .writeText(m)
            .then(() => {})
            .catch((reason) => {
                console.log(reason)
            })
    }

    return (
        <InfoBlock
            title="Network Registrations"
            icon={<Wifi></Wifi>}
            loading={loading}
            expanded={expanded}
            hide={!loading && registrations.length === 0}
        >
            <Paper>
                <ButtonLinks
                    buttons={[
                        {
                            title: "Wiki",
                            href: URL_MOBILE_ACCESS_WIKI,
                            icon: <Help></Help>,
                        },
                        {
                            title: "Mobile Access Administration",
                            href: URL_MOBILE_ACCESS_ADMIN,
                            icon: <ListAlt></ListAlt>,
                        },
                    ]}
                ></ButtonLinks>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>MAC Address</TableCell>
                                <TableCell>Registered Time</TableCell>
                                <TableCell>
                                    Last Seen{" "}
                                    <HelpTooltip tooltip="Last Seen is not updated instantly. It is a general indicator and can take more than 24 hours to be updated with new data!"></HelpTooltip>
                                </TableCell>
                                <TableCell>Comments</TableCell>
                                <TableCell>Manufacturer</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registrations.map((registration) => (
                                <TableRow key={registration.mac_address}>
                                    <TableCell>
                                        <Link
                                            component={RouterLink}
                                            to={
                                                "/search/mac_address/" +
                                                add_mac_separators(registration.mac_address)
                                            }
                                        >
                                            {add_mac_separators(
                                                registration.mac_address.toUpperCase()
                                            )}
                                        </Link>
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {format_date_string(registration.created_DTS)}
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                                        {registration.last_seen !== null
                                            ? format_date_string(registration.last_seen)
                                            : "Never Seen"}
                                    </TableCell>
                                    <TableCell>{registration.comments}</TableCell>
                                    <TableCell>
                                        {registration.vendor === null
                                            ? "UNKNOWN"
                                            : registration.vendor?.company ?? <Skeleton></Skeleton>}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Copy MAC to Clipboard">
                                            <IconButton
                                                size="small"
                                                onClick={copy_mac_to_clipboard(
                                                    registration.mac_address
                                                )}
                                            >
                                                <FileCopy fontSize="small"></FileCopy>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default NetworkRegistrations
