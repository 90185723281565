import { ListItem, ListItemText, Typography } from "@mui/material"
import { Skeleton } from '@mui/material';
import { useQuery } from "react-query"
import { fetchKFSOrgByNumber } from "../../apiFunctions"

interface SupportedDepartmentProps {
    org_number: string
}

const SupportedDepartment = (props: SupportedDepartmentProps) => {
    let { org_number } = props

    let { isLoading, data } = useQuery(
        ["kfs-org-by-org-number", org_number],
        fetchKFSOrgByNumber(org_number)
    )

    return (
        <ListItem disableGutters>
            <ListItemText
                disableTypography
                primary={
                    <Typography variant="body2" color="textPrimary">
                        {org_number} -{" "}
                        {isLoading ? (
                            <Skeleton
                                width="50%"
                                style={{
                                    display: "inline-block",
                                }}
                            ></Skeleton>
                        ) : (
                            data?.org_name
                        )}
                    </Typography>
                }
            ></ListItemText>
        </ListItem>
    )
}

export default SupportedDepartment
