import React, { useEffect } from "react"
import { Collapse, FormControlLabel, Switch } from "@mui/material"

import { APPLICATION_STATE_CONTEXT } from "../PageStructure/ApplicationStateManager/ApplicationStateManager"
import { ComponentKeys } from "../Settings/ComponentKeys"
import useAlerts from "../PageStructure/AlertManager/useAlerts"

import ActiveDirectoryInfo from "../AccountInformation/ActiveDirectoryInfo/ActiveDirectoryInfo"
import BasicUserInfo from "../AccountInformation/BasicUserInfo/BasicUserInfo"
import BlockListForUCInetID from "../NetworkInformation/BlockList/BlockListForUCInetID"
import DepartmentSupport from "../AccountInformation/DepartmentSupport/DepartmentSupport"
import EmployeeInfo from "../AccountInformation/EmployeeInfo/EmployeeInfo"
import LicenseManagement from "../LicenseManagement/LicenseManagement"
import KSAMSInfo from "../AccountInformation/KSAMSInfo/KSAMSInfo"
import NetworkRegistrationsForUCInetID from "../NetworkInformation/NetworkRegistrations/NetworkRegistrationsForUCInetID"
import QuickInfo from "../AccountInformation/QuickInfo/QuickInfo"
import SponsorInfo from "../AccountInformation/SponsorInfo/SponsorInfo"
import StudentInfo from "../AccountInformation/StudentInfo/StudentInfo"



interface Component {
    component: React.ReactNode
}
interface UserViewProps {
    ucinetid: string
}

let UserView = (props: UserViewProps) => {
    let { ucinetid } = props

    let { AlertList, addAlert, clearAlerts} = useAlerts()

    let [appState] = React.useContext(APPLICATION_STATE_CONTEXT)
    let [showAllBlocks, setShowAllBlocks] = React.useState(false)

    let expandedComponents = new Set(appState.account_expanded_blocks)

    useEffect(()=>{
        clearAlerts()// eslint-disable-next-line
    }, [ucinetid])
    

    const COMPONENTS: { [key: string]: Component } = {
        "quick-info": {
            component: (
                <QuickInfo 
                    ucinetid={ucinetid}
                ></QuickInfo>
            ),
        },
        "basic-user-info": {
            component: (
                <BasicUserInfo
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("basic-user-info")}
                    addAlert={addAlert}
                ></BasicUserInfo>
            ),
        },
        "employee-info": {
            component: (
                <EmployeeInfo
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("employee-info")}
                    addAlert={addAlert}
                ></EmployeeInfo>
            ),
        },
        "student-info": {
            component: (
                <StudentInfo
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("student-info")}
                ></StudentInfo>
            ),
        },
        "sponsor-info": {
            component: (
                <SponsorInfo
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("sponsor-info")}
                ></SponsorInfo>
            ),
        },
        "active-directory-info": {
            component: (
                <ActiveDirectoryInfo
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("active-directory-info")}
                ></ActiveDirectoryInfo>
            ),
        },
        "department-support": {
            component: (
                <DepartmentSupport
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("department-support")}
                ></DepartmentSupport>
            ),
        },
        "ksams-info": {
            component: (
                <KSAMSInfo
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("ksams-info")}
                ></KSAMSInfo>
            ),
        },
        "license-management": {
            component: (
                <LicenseManagement
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("license-management")}
                ></LicenseManagement>
            ),
        },
        "network-registrations": {
            component: (
                <NetworkRegistrationsForUCInetID
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("network-registrations")}
                ></NetworkRegistrationsForUCInetID>
            ),
        },
        "block-list": {
            component: (
                <BlockListForUCInetID
                    ucinetid={ucinetid}
                    expanded={expandedComponents.has("block-list")}
                ></BlockListForUCInetID>
            ),
        },
    }

    let blocks = showAllBlocks ? [...ComponentKeys] : appState.account_blocks
    
    return (
        <>
            <FormControlLabel
                label="Show All Information"
                labelPlacement="start"
                control={<Switch onChange={() => setShowAllBlocks(!showAllBlocks)}></Switch>}
            ></FormControlLabel>
            {AlertList}
            <Collapse in={true}>
                {blocks.map((id) => (
                    <React.Fragment key={id}>{COMPONENTS[id].component}</React.Fragment>
                ))}
            </Collapse>
        </>
    )
}

export default UserView
