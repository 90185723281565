import { axios } from "./axios"
import {
    ADInfo,
    AdobeLicenseInfo,
    AdobeLicenseRequestInfo,
    BlockListRecord,
    Building,
    CSCUserData,
    DepartmentSupportData,
    FirewallRule,
    ForescoutHost,
    JPLPort,
    JPLSubnet,
    MACVendorInfo,
    NetworkRegistration,
    NSLookupInfo,
    SubnetsMetadataInterface,
    LDAPForcedAttributes,
    AffiliationOrder,
    KFSOrg,
    KFSOrgContact,
    ForescoutFilteredHost,
    SupportGroup,
    LDAPEntry,
    VisitorNetworkRegistration,
    ResNetNATMapping,
    QuickTempInfo,
    ALMEvent,
    ALMOperators,
    ModelerLicenseInfo,
    ModelerLicenseRequestInfo,
    ServiceNowTicket,
} from "./apiInterfaces"
import { getMainAffiliation } from "./components/Utilities/accountUtilities"
import { remove_mac_address_separators } from "./components/Utilities/macUtilities"

export const fetchActiveDirectoryByUCInetID = (ucinetid?: string) => async () => {
    let res = await axios.get(`/api/v1/ldap/ad/ucinetid/${ucinetid}`)

    let d = res.data as ADInfo[]
    return d?.[0]
}

export const fetchAccountSuggestions = (query: string) => async () => {
    if (query) {
        let params = new URLSearchParams()

        params.append("query", query)
        params.append("limit", "20")
        params.append("attributes", "displayName")
        params.append("attributes", "uciMailDeliveryPoint")
        params.append("attributes", "uid")
        params.append("attributes", "uciAffiliation")
        params.append("attributes", "title")
        params.append("attributes", "department")
        params.append("attributes", "uciStudentLevel")
        params.append("attributes", "uciStudentMajor")

        let r = await axios.get("/api/v1/ldap/private", {
            params: params,
        })

        let data = r.data as Array<{ attributes: LDAPForcedAttributes }>
        let entries = data.map((d) => d["attributes"])

        entries.sort((a, b) => {
            let a_main = getMainAffiliation(a.uciAffiliation)
            let b_main = getMainAffiliation(b.uciAffiliation)

            let a_index = AffiliationOrder.indexOf(a_main)
            let b_index = AffiliationOrder.indexOf(b_main)
            return a_index - b_index
        })

        return entries
    }

    return []
}

export const fetchActiveVisitorRegistrationsByMACAddress = (mac_address: string) => async () => {
    if (mac_address) {
        let res = await axios.get(
            `/api/v1/netlookup/registrations/visitors/mac-address/${mac_address}`
        )

        let data = res.data as VisitorNetworkRegistration[]
        return data
    }
}

export const fetchAdobeLicenseInfoByUCInetID = (ucinetid: string) => async () => {
    if (ucinetid) {
        let res = await axios.get( `/api/v1/alm/private/license/${ucinetid}`)
        let data = res.data as AdobeLicenseInfo
        return data
    }
}

export const fetchALMEventsByQuery = (query: string) => async () =>{
    let r = await axios.get(`/api/v1/alm/private/admin/log?${query}`)
    let {events, offset } = r.data
    return  {"events": events as ALMEvent[], "offset": offset as number}
}

export const fetchALMEventByUCInetID = (ucinetid: string) => async () => {
    if (ucinetid) {
        let res = await axios.get( `/api/v1/alm/private/license/${ucinetid}/log`)
        let data = res.data as ALMEvent[]
        return data
    }
}

export const fetchALMOperators = () => async () =>{
    let r = await axios.get(`/api/v1/alm/private/admin/operators`)
    let data = r.data as [ALMOperators]
    return data
}

export const fetchAllLDAPDataByUCInetID = (ucinetid: string) => async () => {
    if (ucinetid) {
        let params = new URLSearchParams()
        params.append("attributes", "*")
        params.append("attributes", "+")

        let res = await axios.get(`/api/v1/ldap/private/ucinetid/${ucinetid}`, {
            params: params,
        })

        let data = res.data as LDAPEntry[]
        return data?.[0]
    }
}

export const fetchBlockList = (query?: string) => async () => {
    if (query) {
        let res = await axios.get(`/api/v1/netlookup/blocked/search/${query}`)
        let data = res.data as BlockListRecord[]
        return data
    }
    return []
}

export const fetchBuildings = async () => {
    let r = await axios.get(
        "/api/v1/dbnsp/?query=SELECT id,compco_id,abbr_name,address_911,last_updated,alias,published_flag FROM buildings.buildings JOIN buildings.aliases ON buildings.buildings.id=buildings.aliases.building_id&db=buildings"
    )

    let buildings = r.data as Building[]
    buildings.sort((a, b) => a.id - b.id)

    return buildings
}

export const fetchBuildingPorts = (building_name?: string) => async () => {
    if (building_name) {
        let r = await axios.get(
            `/api/v1/dbnsp/db/jack_port_location/table/users?where=building=?&where_args=${building_name}`
        )

        let ports = r.data as JPLPort[]
        return ports
    }
}

export const fetchCSCUsers = async() => {
    let r = await axios.get(`/api/v1/department-support/cscs`)

    let data = r.data as CSCUserData[]
    return data
}

export const fetchCSCUserDataByUCInetID = (ucinetid: string) => async() => {
    let r = await axios.get(`/api/v1/department-support/cscs/${ucinetid}`)

    let data = r.data as CSCUserData | null
    return data
}

export const fetchCurrentUserLDAPData = async () => {
    let r = await axios.get("/api/v1/ldap/private/me", {
        headers: {
            "Cache-Control": "no-cache",
        },
    })
    let data = r.data as LDAPEntry[]
    return data
}

export const fetchDepartmentSupportByDepartmentNumber = (department_number?: string) => async () => {
        let res = await axios.get(
            `/api/v1/department-support/department-number/${department_number}`
        )

        let data = res.data as DepartmentSupportData
        return data
}

export const fetchDepartmentSupportByUCInetID = (ucinetid?: string) => async () => {
    if (ucinetid) {
        let res = await axios.get(`/api/v1/department-support/ucinetid/${ucinetid}`)

        let d = res.data as DepartmentSupportData
        return d
    }
}

/** NO LONGER AVAILABLE!!! */
export const fetchFirewallRules = (host_name: string) => async () => {
    if (host_name) {
        let res = await axios.get("/api/v1/dbnsp/db/server_registration/table/servers", {
            params: {
                where: "host LIKE ?",
                where_args: host_name,
            },
        })

        let rules = res.data as FirewallRule[]
        return rules
    }
    return []
}

export const fetchForescoutHostByMAC = (mac_address: string) => async () => {
    if (mac_address) {
        let fixed_mac = mac_address.replaceAll(":", "").replaceAll("-", "")

        let res = await axios.get(`/api/v1/netlookup/forescout/hosts/mac/${fixed_mac}`)

        let data = res.data as ForescoutHost
        return data
    }
}

export const fetchForescoutHostByIP = (ip_address: string) => async () => {
    if (ip_address) {
        let res = await axios.get(`/api/v1/netlookup/forescout/hosts/ip/${ip_address}`)

        let data = res.data as ForescoutHost
        return data
    }
}

export const fetchForescoutHostsByMAC = (mac_address: string) => async () => {
    mac_address = remove_mac_address_separators(mac_address)
    if (mac_address.length >= 2) {
        let r = await axios.get(
            `/api/v1/netlookup/forescout/filter/mac/${remove_mac_address_separators(mac_address)}`
        )
        let data = (r.data as any)["hosts"] as ForescoutFilteredHost[]
        return data
    }
    return []
}

export const fetchKFSOrgs = async () => {
    let r = await axios.get("/api/v1/department-support/orgs")

    let data = r.data as KFSOrg[]
    return data
}

export const fetchKFSOrgsByName = (org_name?: string) => async () => {
    if (org_name) {
        let r = await axios.get(`/api/v1/department-support/orgs/org-name/${org_name}`)

        let data = r.data as KFSOrg[]
        return data
    }
    return []
}

export const fetchKFSOrgByNumber = (org_code?: string) => async () => {
    if (org_code) {
        let r = await axios.get(`/api/v1/department-support/orgs/department-number/${org_code}`)
        let data = r.data as KFSOrg
        return data
    }
}


export const fetchKFSOrgContactByNumber = (org_code?: string) => async () => {
    if (org_code) {
        let r = await axios.get(`/api/v1/department-support/orgs/department-number/${org_code}/contact`)
        let data = r.data as KFSOrgContact
        return data
    }
}


export const fetchModelerLicenseInfoByUCInetID = (ucinetid: string) => async () => {
    if (ucinetid) {
        let res = await axios.get(`/api/v1/modeler/private/license/${ucinetid}`)
        let data = res.data as ModelerLicenseInfo
        if (data.license_info?.assigned_by === "SYSTEM") {
            data.license_info.assignment_reason = "ELIGIBLE"
        }
        return data
    }
}

export const fetchNSLookupInfo = (host_name: string) => async () => {
    if (host_name) {
        let r = await axios.get(`/api/v1/netlookup/hosts/nslookup/${host_name}`)
        let data = r.data as NSLookupInfo
        return data
    }
}

export const fetchQuickTempDataByUCInetID = (ucinetid?: string) => async () => {
    if (ucinetid) {
        let res = await axios.get(`/api/v1/department-support/quicktemp/${ucinetid}`)

        let d = res.data as QuickTempInfo
        return d
    }
}

export const fetchRegistrationByMACAddress = (mac_address: string) => async () => {
    if (mac_address) {
        let res = await axios.get(`/api/v1/netlookup/registrations/mac-address/${mac_address}`)

        let data = res.data as NetworkRegistration[]
        return data
    }
}

export const fetchRegistrationsByUCInetID = (ucinetid: string) => async () => {
    if (ucinetid) {
        let res = await axios.get(`/api/v1/netlookup/registrations/ucinetid/${ucinetid}`)

        let data = res.data as NetworkRegistration[]
        return data
    }
}

export const fetchResNetNAT = (ip: string) => async () => {
    let r = await axios.get(`/api/v1/netlookup/ip/nat/${ip}`)

    let data = r.data as ResNetNATMapping | null
    return data
}

export const fetchSubnetInformation = (subnet_name: string) => async () => {
    let res = await axios.get(`/api/v1/netlookup/subnets/jpl_network_name/${subnet_name}`)

    let data = res.data as JPLSubnet[]
    return data
}

export const fetchSubnetTableMetadata = async () => {
    let r = await axios.get("/api/v1/netlookup/subnets/metadata")

    let data = r.data as SubnetsMetadataInterface
    return data
}

export const fetchSupportGroups = async () => {
    let r = await axios.get("/api/v1/department-support/groups")

    let data = r.data as SupportGroup[]
    return data
}

export const fetchSupportGroupByName = (name: string) => async () => {
    if (name) {
        let r = await axios.get(`/api/v1/department-support/groups/${name}`)

        let data = r.data as SupportGroup
        return data
    }
}

export const fetchVendorForMACAddress = (mac_address: string) => async () => {
    if (mac_address) {
        let res = await axios.get(`/api/v1/netlookup/mac-address/${mac_address}/vendor`)

        let data = res.data as MACVendorInfo | null
        return data
    }
}

export const fetchServiceNowZoomTickets = () => async () => {
    let res = await axios.get("/api/v1/sn/zoom-tickets/active")

    let data = res.data as ServiceNowTicket[] 
    return data
}



export const updateAdobeLicense = async(request_info: AdobeLicenseRequestInfo) => {
    let res = await axios.post("/api/v1/alm/private/license", {request_data: request_info})
    let json: {
        removed: string[]
        added: string
    } = res.data
    return json
}

export const updateModelerLicense = async(request_info: ModelerLicenseRequestInfo) =>{
    let res = await axios.post("/api/v1/modeler/private/license", {request_data: request_info})
    let json: {success: boolean} = res.data
    return json
}
