import { Box, CircularProgress, Fade } from "@mui/material"
import React from "react"
import { SwitchTransition } from "react-transition-group"
import LandingPage from "../../LandingPage/LandingPage"
import { useQuery } from "react-query"
import { fetchCurrentUserLDAPData } from "../../../apiFunctions"
import { LDAPAttributes } from "../../../apiInterfaces"

// let isDevelopment = process.env.NODE_ENV === "development"

export const CurrentUserLDAPContext = React.createContext<LDAPAttributes | undefined>(undefined)

interface LoginControllerProps {
    children: React.ReactNode
}

let LoginController = (props: LoginControllerProps) => {
    let [loggedIn, setLoggedIn] = React.useState(false)

    let { isLoading, isError, data } = useQuery("current-user-ldap", fetchCurrentUserLDAPData, {
        staleTime: 60 * 60 * 1000, // 1 hour in ms
        retry: false,
    })

    const ldap_entry = data?.[0]?.attributes

    React.useEffect(() => {
        // Actual Login Logic
        if (!isLoading && !isError && (ldap_entry?.uid?.length ?? []) > 0) {
            setLoggedIn(true)
        }
    }, [isLoading, isError, ldap_entry])

    return (
        <SwitchTransition>
            <Fade appear key={isLoading ? "1" : "0"}>
                <Box width="100%" height="100%">
                    {isLoading && (
                        <Box
                            height="100%"
                            width="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress thickness={1} size="5rem"></CircularProgress>
                        </Box>
                    )}
                    <CurrentUserLDAPContext.Provider value={data?.[0]?.attributes}>
                        {!isLoading && (loggedIn ? props.children : <LandingPage />)}
                    </CurrentUserLDAPContext.Provider>
                </Box>
            </Fade>
        </SwitchTransition>
    )
}

export default LoginController
