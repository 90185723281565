import { Fab } from "@mui/material"
import { blue, purple } from "@mui/material/colors"
import { Poll } from "@mui/icons-material"
import React from "react"
import FeedbackDialog from "./FeedbackDialog"

const color = blue[500]
const hoverColor = blue["A400"]

let FeedbackButton = (props) => {
    let [dialogOpen, setDialogOpen] = React.useState(false)

    //if hide feedback button in iframe
    if (window.frameElement !== null){
        return <></>
    }

    return (
        <>
            <Fab
                sx={{
                    color: (theme) => theme.palette.getContrastText(color),
                    transition:
                        "background-color 0.5s, transform 0.5s cubic-bezier(0.4, 0.75, 0.2, 1.20) 2s",
                    backgroundColor: color,
                    "&:hover": {
                        backgroundColor: hoverColor,
                    },
                    position: "absolute",
                    bottom: (theme) => theme.spacing(3),
                    right: (theme) => theme.spacing(3),
                }}
                onClick={() => setDialogOpen(true)}
            >
                <Poll />
            </Fab>
            <FeedbackDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} />
        </>
    )
}

export default FeedbackButton
