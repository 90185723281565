import { Alert, AlertTitle, Box } from "@mui/material"

import { useParams } from "react-router-dom"

import { ValidSearchType } from "./Search"

import UserView from "../Views/UserView"
import MACAddressView from "../Views/MACAddressView"
import SupportGroupView from "../Views/SupportGroupView"
import DepartmentView from "../Views/DepartmentView"
import WebsiteView from "../Views/WebsiteView"
import { IPAddressView } from "../Views/IPAddressView"

let SearchResults = () => {
    const params = useParams()
    const searchType = params.type
    const searchValue = params.query || ""

    return (
        <Box display="flex" flexDirection="column">
            {searchType === "null" && (
                <Alert severity="error">
                    <AlertTitle>Unable to determine what "{searchValue}" is.</AlertTitle>
                    HDUtils couldn't find a matching UCInetID, ID Number, Name, MAC Address, IP
                    Address, etc... or HDUtils doesn't support searching for this. If HDUtils should
                    recognize what this is, please send us feedback or contact the developers.
                </Alert>
            )}
            {searchType === ValidSearchType.ucinetid && (
                <UserView ucinetid={searchValue}></UserView>
            )}
            {searchType === ValidSearchType.mac_address && (
                <MACAddressView
                    searchValue={searchValue}
                    searchType={searchType}
                    manuallySubmitted={false}
                ></MACAddressView>
            )}
            {searchType === ValidSearchType.support_group && (
                <SupportGroupView
                    searchValue={searchValue}
                    searchType={searchType}
                    manuallySubmitted={false}
                ></SupportGroupView>
            )}
            {searchType === ValidSearchType.department && (
                <DepartmentView
                    searchValue={searchValue}
                    searchType={searchType}
                    manuallySubmitted={false}
                ></DepartmentView>
            )}
            {searchType === ValidSearchType.subnet && (
                <>Whoops. We currently don't have a detail view for Subnets.</>
            )}
            {searchType === ValidSearchType.host_name && (
                <WebsiteView
                    searchType={searchType}
                    searchValue={searchValue}
                    manuallySubmitted={false}
                ></WebsiteView>
            )}
            {searchType === ValidSearchType.ip_address && (
                <IPAddressView
                    searchType={searchType}
                    searchValue={searchValue}
                    manuallySubmitted={false}
                />
            )}
        </Box>
    )
}

export default SearchResults
