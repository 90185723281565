import {
    Box,
    CircularProgress,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { Alert, AlertTitle } from '@mui/material';
import { axios } from "../../../axios"
import { useQuery } from "react-query"
import { BlurOn, Note, Notes, Web, WifiLock } from "@mui/icons-material"
import _ from "lodash"
import { BlockListRecord } from "../../../apiInterfaces"
import ButtonLinks from "../../GenericBlocks/ButtonLinks/ButtonLinks"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"

interface CovidBlock {
    no_mobile_access: boolean
}

interface BlockListProps {
    loading: boolean
    expanded?: boolean
    ucinetid?: string
    ucinetidBlocked: boolean
    blocks: BlockListRecord[]
}

let BlockList = (props: BlockListProps) => {
    let { loading, expanded = false, ucinetid, ucinetidBlocked, blocks } = props

    let {
        isLoading: covidLoading,
        isError: covidError,
        data: covidData,
    } = useQuery(
        ["covid-block", ucinetid],
        async () => {
            const res = await axios.get(`/api/v1/netlookup/blocked/covid-blocks/${ucinetid}`)

            let data = res.data as CovidBlock
            return data
        },
        {
            enabled: ucinetid !== undefined && ucinetid !== "",
        }
    )

    const covid_blocked = covidData?.no_mobile_access

    let devicesBlocked = _.some(blocks, (b) => b.what.includes(":"))

    return (
        <InfoBlock
            title="Block List"
            icon={<WifiLock></WifiLock>}
            hide={!loading && blocks.length === 0 && !covid_blocked}
            expanded={expanded}
            loading={loading}
        >
            {ucinetid && (
                <Box paddingBottom="1rem">
                    <Alert
                        severity={
                            covidLoading
                                ? "info"
                                : covidError
                                ? "warning"
                                : covid_blocked
                                ? "error"
                                : "success"
                        }
                    >
                        <AlertTitle>
                            <b>Student Affairs Covid Block</b>
                        </AlertTitle>
                        {covidLoading ? (
                            "Loading..."
                        ) : covidError ? (
                            "There was an ERROR checking if this UCInetID is blocked, please contact the devs if this happens often."
                        ) : covid_blocked ? (
                            <>
                                The UCInetID {ucinetid.toUpperCase()} is BLOCKED because they are
                                not COVID compliant!
                            </>
                        ) : (
                            <>
                                The UCInetID {ucinetid.toUpperCase()} is COVID compliant and is NOT
                                blocked.
                            </>
                        )}
                    </Alert>
                </Box>
            )}
            <Paper>
                <ButtonLinks
                    buttons={[
                        {
                            title: "SA Covid Blocks",
                            href: "https://services.sa.uci.edu/admin/resources/no-mobile-access",
                            icon: <BlurOn></BlurOn>,
                        },
                        {
                            title: "IT Security Wiki",
                            href: "https://wiki.oit.uci.edu/pages/viewpage.action?pageId=1199571145",
                            icon: <Note></Note>,
                        },
                        {
                            title: "Block Information",
                            href: "https://wiki.oit.uci.edu/pages/viewpage.action?pageId=1235058842",
                            icon: <Notes></Notes>,
                        },
                        {
                            title: "Block List",
                            href: "https://iam-apps.oit.uci.edu/blocked",
                            icon: <Web></Web>,
                        },
                    ]}
                ></ButtonLinks>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="UCInetID Blocked?">
                                {loading ? (
                                    <CircularProgress></CircularProgress>
                                ) : ucinetidBlocked ? (
                                    "Yes!"
                                ) : (
                                    "No"
                                )}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Devices Blocked?">
                                {loading ? (
                                    <CircularProgress></CircularProgress>
                                ) : devicesBlocked ? (
                                    "Yes!"
                                ) : (
                                    "No"
                                )}
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                    {blocks.length > 0 && (
                        <>
                            <Divider></Divider>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>What (MAC, UCInetID, etc...)</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Blocked Added By</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Where</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>When</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>Why</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>How</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {blocks.map((block) => (
                                        <TableRow>
                                            <TableCell>{block.what}</TableCell>
                                            <TableCell>{block.who}</TableCell>
                                            <TableCell>{block.wher}</TableCell>
                                            <TableCell>{block.when}</TableCell>
                                            <TableCell>{block.why}</TableCell>
                                            <TableCell>{block.how}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default BlockList
