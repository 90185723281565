import { ViewProps } from "../Search/Search"
import SupportGroupInfo from "../SupportGroupInfo/SupportGroupInfo"
import { useQuery } from "react-query"
import { fetchSupportGroupByName } from "../../apiFunctions"

let SupportGroupView = (props: ViewProps) => {
    let { searchValue } = props

    let { isLoading, data } = useQuery(
        ["support-group-by-name", searchValue],
        fetchSupportGroupByName(searchValue)
    )

    return (
        <>
            <SupportGroupInfo
                loading={isLoading}
                support_group={data}
            ></SupportGroupInfo>
        </>
    )
}

export default SupportGroupView
