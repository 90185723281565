import React from "react"
import { useRandomPartyParrot } from "../../DataHooks/useRandomPartyParrot"

let DynamicFavicons = () => {
    let parrotURL = useRandomPartyParrot()

    React.useEffect(() => {
        let link = document.getElementById("favicon") as HTMLLinkElement
        link.href = parrotURL
    })

    return null
}

export default DynamicFavicons
