import { Grid, Box, FormControlLabel, Checkbox, Button } from "@mui/material"
import { useEffect, useState } from "react"
import DateRangePicker from "../GenericBlocks/DateRangePicker"
import MultiSelector from "../GenericBlocks/MultiSelector"

const today = new Date()
const lastWeek = new Date()
lastWeek.setDate(today.getDate() - 7)
const parseDate = (date: Date) => `${date.toLocaleDateString("sv")}`

const allTypes = [
    "LICENSE_ASSIGNED",
    "LICENSE_REVOKED",
    "MARKED_INELIGIBLE",
    "NOTIFICATION_EMAIL_SENT",
    "REVOCATION_CANCELED"
]

interface LicenseMangagerLogSearchFormProps {
    operators: string[],
    types: string[],
    onSubmit: (query: string) => void
}

let LicenseManagerLogSearchForm = (props: LicenseMangagerLogSearchFormProps) => {
    let {operators, types, onSubmit } = props
    let defaultValues = {
        startDate: lastWeek, //set default start date to yesterday
        endDate: today,
        operators: operators,
        types: types,
        hideSystem: true
    }

    let [formValues, setFormValues] = useState(defaultValues)

    const handleSubmit = (e?: any) => {
        e && e.preventDefault();
        
        //ugh, we need to increment the end date. 
        //EX: Fetching events on 9/15 23:59 would require setting end date to 9/15
        let endDate = new Date(formValues.endDate)
        endDate.setDate(formValues.endDate.getDate()+1)

        onSubmit(`start_date=${parseDate(formValues.startDate)}&` +
        `end_date=${parseDate(endDate)}&` +
        `hide_system=${formValues.hideSystem}&` +
        `operators=${formValues.operators.join("&operators=")}&` +
        `types=${formValues.types.join("&types=")}`)
    }

    useEffect(()=>{
        handleSubmit()
        // eslint-disable-next-line
    }, [])

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} padding="2rem">
                <Grid item xs={6}>
                    <DateRangePicker
                        defaultDateRange={[lastWeek, today]}
                        disableFuture={true}
                        onChange={(dateRange: [Date, Date]) =>
                            setFormValues({ ...formValues, startDate: dateRange[0], endDate: dateRange[1] })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <MultiSelector
                        name="Operators"
                        items={operators}
                        defaultItemIndexes={defaultValues.operators.map((operator) => (operators.indexOf(operator)))}
                        onSelect={(operators: string[]) =>
                            setFormValues({ ...formValues, operators: operators })
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <MultiSelector
                        name="Event Types"
                        items={allTypes}
                        defaultItemIndexes={defaultValues.types.map((type) => (types!.indexOf(type)))}
                        onSelect={(types: string[]) => setFormValues({ ...formValues, types: types })}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="right">
                    <Box mr="2rem">
                        <FormControlLabel
                            label="Hide System Operations"
                            control={
                                <Checkbox
                                    checked={formValues.hideSystem}
                                    onClick={() =>
                                        setFormValues({ ...formValues, hideSystem: !formValues.hideSystem })
                                    }
                                />
                            }
                        />
                    </Box>
                    <Button type="submit" variant="outlined">Search</Button>
                </Grid>
            </Grid>
        </form>
    )
}


export default LicenseManagerLogSearchForm