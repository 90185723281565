import { useQuery } from "react-query"
import { fetchRegistrationByMACAddress, fetchVendorForMACAddress } from "../../apiFunctions"

let useMACAddressNetworkRegistrations = (mac_address: string) => {
    let {
        isLoading: netreg_loading,
        isError: netreg_error,
        data: registrations,
    } = useQuery(
        ["network-registrations-by-mac", mac_address],
        fetchRegistrationByMACAddress(mac_address)
    )
    let {
        // isLoading: vendor_loading,
        isError: vendor_error,
        data: vendorInfo,
    } = useQuery(["vendor-info-for-mac", mac_address], fetchVendorForMACAddress(mac_address))

    if (vendorInfo) {
        let reg = registrations?.find((r) => r.mac_address === mac_address)
        if (reg) {
            reg.vendor = vendorInfo
        }
    }

    return {
        loading: netreg_loading,
        error: netreg_error || vendor_error,
        registrations: registrations,
    }
}

export default useMACAddressNetworkRegistrations
