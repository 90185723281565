import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Link,
} from "@mui/material"
import { Check, Close, SettingsEthernet, WifiLock } from "@mui/icons-material"
import { Alert, AlertTitle } from "@mui/material"
import { useQuery } from "react-query"
import { fetchBlockList, fetchResNetNAT } from "../../apiFunctions"
import { SUBNETS } from "../../data/subnets"
import InfoBlock from "../GenericBlocks/InfoBlock/InfoBlock"
import { ViewProps } from "../Search/Search"
import { convert_ip_to_bytes } from "../Utilities/ipUtilities"

export const IPAddressView = (props: ViewProps) => {
    let ip = props.searchValue.trim()

    let { isLoading: blockListIsLoading, data: ip_block } = useQuery(
        ["block-list", ip],
        fetchBlockList(ip)
    )

    let { isLoading: nat_loading, data: ip_nat } = useQuery(["resnet-nat", ip], fetchResNetNAT(ip))

    const entered_ip_is_blocked = ip_block !== undefined && ip_block.length > 0
    const ip_as_number = convert_ip_to_bytes(ip)
    const subnets = SUBNETS.filter(
        (subnet) => subnet.range_end >= ip_as_number && subnet.range_start <= ip_as_number
    )

    const public_ip = ip_nat?.public_ip
    const is_nat_ip = public_ip !== undefined

    let { isLoading: publicIPBlockListLoading, data: public_ip_block } = useQuery(
        ["block-list", public_ip],
        fetchBlockList(public_ip),
        {
            enabled: public_ip !== undefined,
        }
    )

    const public_ip_blocked = public_ip_block !== undefined && public_ip_block.length > 0

    const ip_blocked = entered_ip_is_blocked || public_ip_blocked

    return (
        <>
            <InfoBlock
                title="Block List"
                icon={<WifiLock></WifiLock>}
                loading={blockListIsLoading || nat_loading || publicIPBlockListLoading}
                expanded
            >
                <Paper>
                    {ip_blocked ? (
                        <>
                            <Alert severity="error">
                                <AlertTitle>The IP Address {ip} IS BLOCKED!</AlertTitle>
                                Please use the instructions{" "}
                                <Link
                                    href="https://wiki.oit.uci.edu/display/OITHD/Blocks+and+Disconnects+Troubleshooting"
                                    target="_blank"
                                >
                                    here
                                </Link>{" "}
                                to resolve the issue.{" "}
                                {is_nat_ip ? (
                                    <>
                                        The IP Address {ip} is a NAT (Private) IP Address and maps
                                        to the Public IP address {public_ip}.{" "}
                                        {public_ip_blocked ? (
                                            <>The Public IP Address {public_ip} is BLOCKED.</>
                                        ) : (
                                            <>The Private IP Address {ip} is BLOCKED.</>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        The IP Address {ip} is not a known NAT (Private) IP Address.
                                    </>
                                )}
                            </Alert>
                            <Paper>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {["What", "Where", "Who", "When", "Why", "How"].map(
                                                    (header) => (
                                                        <TableCell key={header}>{header}</TableCell>
                                                    )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {[...(ip_block ?? []), ...(public_ip_block ?? [])].map(
                                                (block) => (
                                                    <TableRow key={block.what}>
                                                        <TableCell>{block.what}</TableCell>
                                                        <TableCell>{block.wher}</TableCell>
                                                        <TableCell>{block.who}</TableCell>
                                                        <TableCell>{block.when}</TableCell>
                                                        <TableCell>{block.why}</TableCell>
                                                        <TableCell>{block.how}</TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </>
                    ) : (
                        <Alert severity="success">
                            <AlertTitle>The IP Address {ip} is not blocked</AlertTitle>
                            {is_nat_ip ? (
                                <>
                                    The IP Address {ip} is a NAT (Private) IP Address and maps to
                                    the Public IP address {public_ip}. Neither IP Address is
                                    blocked.
                                </>
                            ) : (
                                <>The IP Address {ip} is not a known NAT (Private) IP Address.</>
                            )}
                        </Alert>
                    )}
                </Paper>
            </InfoBlock>
            <InfoBlock title="Subnet" icon={<SettingsEthernet></SettingsEthernet>}>
                <Paper>
                    {subnets.length > 0 ? (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Range</TableCell>
                                        <TableCell>Provides Internet</TableCell>
                                        <TableCell>Notes</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subnets.map((subnet) => (
                                        <TableRow key={subnet.range}>
                                            <TableCell>{subnet.name}</TableCell>
                                            <TableCell>{subnet.range}</TableCell>
                                            <TableCell align="center">
                                                {subnet.internet ? (
                                                    <Check></Check>
                                                ) : (
                                                    <Close></Close>
                                                )}
                                            </TableCell>
                                            <TableCell>{subnet.message}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Alert severity="warning">No Subnets Found for the given IP</Alert>
                    )}
                </Paper>
            </InfoBlock>
        </>
    )
}
