import { SearchSuggestionsState } from "../SearchSuggestions"
import { SUPPORT_GROUP_ALIASES } from "../../../../data/support_groups"
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material"
import { ValidSearchType } from "../../Search"
import { Group } from "@mui/icons-material"
import { lightBlue } from "@mui/material/colors"

type SUPPORT_GROUP_KEYS = keyof typeof SUPPORT_GROUP_ALIASES

let searchSupportGroups = (support_group_name: string) => {
    let group_names = new Set<string>()

    let key: SUPPORT_GROUP_KEYS
    for (key in SUPPORT_GROUP_ALIASES) {
        if (key.toLowerCase().includes(support_group_name.toLowerCase())) {
            group_names.add(key)
        } else {
            for (let alias of SUPPORT_GROUP_ALIASES[key]) {
                if (alias.includes(support_group_name)) {
                    group_names.add(key)
                }
            }
        }
    }

    return [...group_names]
}

let makeSupportGroupListItem = (support_group_name: string) => {
    return (
        <ListItem button key={support_group_name}>
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: lightBlue["A400"] }}>
                    <Group></Group>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={support_group_name}
                secondary="Support Group"
            ></ListItemText>
        </ListItem>
    )
}

let useSupportGroupsSearchSuggestions = (
    searchInput: string
): SearchSuggestionsState => {
    let group_names = searchSupportGroups(searchInput)

    return {
        loading: true,
        error: false,
        searchSuggestions: group_names.map((name) => ({
            value: name,
            type: ValidSearchType.support_group,
            component: makeSupportGroupListItem(name),
        })),
    }
}

export default useSupportGroupsSearchSuggestions
