import { Grid, Box, IconButton, Tooltip } from "@mui/material"
import React from "react"

interface ButtonLinksProps {
    buttons: {
        icon: React.ReactNode
        href: string
        title: string
    }[]
}

let ButtonLinks = (props: ButtonLinksProps) => {
    let { buttons } = props

    return (
        <Box pt="1rem" pr="1rem">
            <Grid container justifyContent="flex-end" spacing={2}>
                {buttons.map((button) => (
                    <Grid item key={button.href}>
                        <Tooltip title={button.title}>
                            <IconButton href={button.href} target="_blank" size="large">
                                {button.icon}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ButtonLinks
