import React from "react"

import NSLookupInfo from "../NetworkInformation/NSLookupInfo/NSLookupInfo"
import { ViewProps } from "../Search/Search"

let WebsiteView = (props: ViewProps) => {
    return (
        <>
            <NSLookupInfo host={props.searchValue}></NSLookupInfo>
            {/* <FirewallRules url={props.searchValue}></FirewallRules> */}
        </>
    )
}

export default WebsiteView
