import {
    List,
    Drawer,
    Toolbar,
    ListItem,
    ListItemIcon,
    Tooltip,
    Link,
    drawerClasses,
} from "@mui/material"
import { NavLink } from "react-router-dom"
import { Route } from "../../../routes"

interface SidebarListProps {
    routes: Route[]
}

let SidebarList = (props: SidebarListProps) => {
    let { routes } = props

    return (
        <Drawer
            variant={"permanent"}
            sx={{
                zIndex: 0,
                [`& .${drawerClasses["paper"]}`]: {
                    position: "relative",
                }
            }}
        >
            <Toolbar></Toolbar>
            <List dense sx={{overflowX: "hidden"}}>
                {routes.map((r) => (
                    <Tooltip key={r.path} title={r.name} placement="right" arrow>
                        <Link component={NavLink} to={r.link || r.path}>
                            <ListItem button sx={{
                                '&:hover': {
                                    transform: "scale(1.3)",
                                    transition: "transform .3s ease",
                                }
                            }}>
                                <ListItemIcon sx={{
                                    minWidth: "auto"
                                }}>
                                    {r.icon && <r.icon />}
                                </ListItemIcon>
                            </ListItem>
                        </Link>
                    </Tooltip>
                ))}
            </List>
        </Drawer>
    )
}

export default SidebarList
