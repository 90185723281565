import React from "react"
import { DSTableBlock } from "./DSTableBlock"
import { Alert, AlertTitle, Box, Paper, Typography } from "@mui/material"
import { useQuery } from "react-query"
import { fetchKFSOrgs, fetchSupportGroups } from "../../apiFunctions"
import { KFSOrg, SupportGroup } from "../../apiInterfaces"
import { Skeleton } from '@mui/material';
import { DSEditDialog } from "./DSEditDialog"

const TOP_LEVEL_PARENT_ORG = "IRVN"

export interface Orgs {
    [org: string]: KFSOrg
}

export interface OrgHierarchy {
    [org: string]: Set<string>
}

const DepartmentSupportAdmin = () => {
    let [dialogOpen, setDialogOpen] = React.useState(false)
    let [currentOrg, setCurrentOrg] = React.useState<string | undefined>(undefined)

    let { isFetching: sp_fetching, data: support_groups } = useQuery(["support-groups"], fetchSupportGroups)
    let { isLoading: orgs_loading, isError: orgs_error, data: kfs_orgs } = useQuery(["kfs-orgs"], fetchKFSOrgs)

    let support_group_dict: { [org_code: string]: SupportGroup } = React.useMemo(() => {
        if (support_groups && sp_fetching===false) {
            let sg_dict: { [org_code: string]: SupportGroup } = {}

            for (let support_group of support_groups) {
                for (let org of support_group.supported_department_numbers) {
                    sg_dict[org] = support_group
                }
            }
            return sg_dict
        } else {
            return {}
        }
    }, [support_groups, sp_fetching])


    let [org_dict, org_hierarchy_dict] = React.useMemo(() => {
        let orgs: { [key: string]: KFSOrg } = {}
        let org_hierarchy_dict: { [key: string]: Set<string> } = {}

        for (let org of kfs_orgs ?? []) {
            orgs[org.department_number] = org

            if (org_hierarchy_dict[org.org_parent_code] === undefined) {
                org_hierarchy_dict[org.org_parent_code] = new Set()
            }

            org_hierarchy_dict[org.org_parent_code].add(org.department_number)
        }
        return [orgs, org_hierarchy_dict]
    }, [kfs_orgs])

    let handleOrgClick = (org_code: string) => () => {
        setDialogOpen(true)
        setCurrentOrg(org_code)
    }

    return (
        <Box pb={"2rem"} pt={"2rem"}>
            <Typography>
                NOTE: This tool is <b>SLOW</b>. Please be patient, there are a lot of orgs and React
                wants to render all of them. Try to avoid rage clicking and expect about a 5 second
                delay for every action.
            </Typography>
            <Typography>
                NOTE: Hold <code>Shift</code> while scrolling to scroll horizontally;
                Scroll to the bottom then use ctrl+f for faster locating.
            </Typography>
            <Typography>
                Orgs with a Support Group will be colored. GENERALLY, a child org will be supported
                by the same org as its parent org.
            </Typography>
            <DSEditDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                org_dict={org_dict}
                support_groups={support_groups}
                org={currentOrg}
            ></DSEditDialog>
            <Paper elevation={5}>
                <Box overflow="auto" fontSize="0.5rem" >
                    {orgs_loading ? (
                        <Skeleton variant="rectangular" height="10rem" />
                    ) : orgs_error ? (
                        <Alert severity="error">
                            <AlertTitle>
                                <b>Error</b>
                            </AlertTitle>
                            Error fetctching support groups and orgs. Try to refresh the page or contact dev team.
                        </Alert>
                    ) : (
                        <DSTableBlock
                            org={TOP_LEVEL_PARENT_ORG}
                            org_hierarchy={org_hierarchy_dict}
                            orgs={org_dict}
                            handleOrgClick={handleOrgClick}
                            support_groups={support_group_dict}
                        ></DSTableBlock>
                    )}
                </Box>
            </Paper>
        </Box>
    );
}
export default DepartmentSupportAdmin
