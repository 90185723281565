import React from "react"
import { Check } from "@mui/icons-material"
import { green } from "@mui/material/colors"
import { keyframes } from "@mui/styled-engine"

const fadeInAndOut = keyframes`
    from {
        opacity: 0;
        transform: rotate(-3turn);
    },
    25% {
        opacity: 1;
        transform: rotate(0.15turn);
    },
    40% {
        transform: rotate(0turn);
    },
    75% {
        opacity: 1;
    },
    to {
        opacity: 0;
    }
`

let SpinningCheckMark = (props) => {
    let [mounted, setMounted] = React.useState(true)

    let handleAnimationEnd = (e) => {
        setMounted(false)
    }

    return mounted ? (
        <Check
            onAnimationEnd={handleAnimationEnd}
            sx={{
                // opacity: 0,
                color: green[500],
                animation: `${fadeInAndOut} 3s`,
            }}
        ></Check>
    ) : null
}

export default SpinningCheckMark
