import React from "react"
import { Paper, Table, TableBody, TableContainer } from "@mui/material"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import { SupervisorAccount } from "@mui/icons-material"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import { format_date_string } from "../../Utilities/dateUtilities"
import useLDAP from "../../DataHooks/useLDAP"

interface SponsorInfoProps {
    ucinetid: string
    expanded?: boolean
}

let SponsorInfo = (props: SponsorInfoProps) => {
    let { ucinetid, expanded = false } = props

    let { loading, entry } = useLDAP(ucinetid)

    let { uciSponsorID, uciSponsorDepartment, uciSponsorDepartmentCode, uciGuestExpiration } =
        entry?.attributes ?? {}

    return (
        <InfoBlock
            icon={<SupervisorAccount></SupervisorAccount>}
            title="Sponsor Info"
            loading={loading}
            expanded={expanded}
            hide={!loading && !(uciSponsorID || uciGuestExpiration)}
        >
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="Sponsor's UCInetID">
                                {uciSponsorID}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Sponsor's Department">
                                {uciSponsorDepartmentCode} - {uciSponsorDepartment}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="UCInetID Expiration">
                                {uciGuestExpiration !== undefined &&
                                    format_date_string(uciGuestExpiration)}
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default SponsorInfo
