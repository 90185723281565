import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material"
import PropTypes from "prop-types"
import React from "react"

let FeedbackDialog = (props) => {
    return (
        <>
            <Dialog open={props.open} onClose={props.handleClose}>
                <DialogTitle>Feedback</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{p:"1rem"}}>
                        <DialogContentText>
                            Please send your feedback directly to the developers (for now)...
                        </DialogContentText>
                        <Grid container justifyContent="center" sx={{mt:"1rem"}}>
                            <Button
                                color="primary"
                                variant="contained"
                                href="https://teams.microsoft.com/l/channel/19%3abf88ac01265b4b6296e458f967e7d271%40thread.skype/HDUtils%2520Feedback?groupId=5f0e8382-ccdc-424f-9923-afbbb0c4d30d&tenantId=68d4533c-eae6-4833-99cc-7a9704ac870b"
                                target="_blank"
                            >
                                Send Feedback!
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

FeedbackDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
}

export default FeedbackDialog
