import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Typography
} from "@mui/material"
import { useTheme } from '@mui/material/styles'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Fragment, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import { updateAdobeLicense } from "../../apiFunctions"
import { AdobeLicense, AdobeLicenseRequestInfo } from "../../apiInterfaces"
import { getLicenseData } from "./LicenseDescription"



interface LicenseMoeificationDialogProps {
    ucinetid: string
    open: boolean
    handleClose: () => void
    currentLicense: AdobeLicense
}

const today = new Date()
const thirtyDaysLater = new Date()
thirtyDaysLater.setDate(today.getDate()+30)

const LicenseModificationDialog = (props: LicenseMoeificationDialogProps) => {
    const theme = useTheme()
    const queryClient = useQueryClient()
    let [selectedLicense, setSelectedLicense] = useState(props.currentLicense)
    let [reason, setReason] = useState("")
    let [expirationDate, setExpirationDate] = useState<Date | null>(today)
    let [submitted, setSubmitted] = useState(false)
    let licenseOptions = [
        AdobeLicense.CreativeCloud,
        AdobeLicense.AcrobatDC,
        AdobeLicense.None]


    const mutation = useMutation(updateAdobeLicense, {
        onMutate: async (requestInfo) => {
            await queryClient.cancelQueries("update-adobe-license")
            const previousRequestInfo = queryClient.getQueryData("update-adobe-license")
            queryClient.setQueryData("update-adobe-license", requestInfo)
            return { previousRequestInfo }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["fetch-adobe-license-by-ucinetid", props.ucinetid], { exact: true })
            props.handleClose()
        },
    })

    const saveLicense = () => {
        let expDate: string | null = null
        if (expirationDate != null) {
            if(expirationDate.getTime() > thirtyDaysLater.getTime()) return;
            expDate = `${expirationDate.getFullYear()}-${expirationDate.getMonth() + 1}-${expirationDate.getDate()}`
        }
        if (selectedLicense === AdobeLicense.None) {
            expDate = null
        }
        const requestInfo = {
            ucinetid: props.ucinetid,
            reason: reason,
            expiration_date: expDate,
            requested_license: selectedLicense
        } as AdobeLicenseRequestInfo
        console.log(requestInfo)
        mutation.mutate(requestInfo)
        setSubmitted(true)
    }

    if (submitted === true && mutation.isSuccess) {
        setSubmitted(false)
    }

    useEffect(() => {
        if (props.open === true) {
            setSelectedLicense(props.currentLicense)
            setExpirationDate(today)
            setReason("")
        }
    }, [props.open, props.currentLicense])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog open={props.open} onClose={props.handleClose} >
                <DialogTitle>Adobe License Modification</DialogTitle>
                <DialogContent>
                    {submitted === true && mutation.isLoading ? (
                        <Box display="flex" justifyContent="flex-center" flexDirection="column" alignItems="center" >
                            <CircularProgress size="10rem" />
                            <Typography mt="2rem">Changing The License...</Typography>
                        </Box>
                    ) : (submitted === true && mutation.isError) ? (
                        <Box display="flex" justifyContent="flex-center" flexDirection="column" alignItems="center" >
                            <Alert severity="error" sx={{ width: '100%' }}>
                                An Error Occurred While Changing Your License.
                                <br />
                                Please reload the page and try again or contact dev team.
                            </Alert>
                        </Box>
                    ) : (
                        <Fragment>
                            <Grid container spacing={3} sx={{ p: "1rem" }} justifyContent="center">
                                {licenseOptions.map((license) => {
                                    const licenseData = getLicenseData(license)
                                    let backgroundColor = theme.palette.mode === 'dark' ? '#444' : '#fff'
                                    let selected = selectedLicense === license
                                    if (selected) {
                                        backgroundColor = theme.palette.mode === 'dark' ? '#666' : '#DFEFFF'
                                    }
                                    return (
                                        <Grid item key={license} sm={4}>
                                            <Paper
                                                sx={{
                                                    height: "9rem",
                                                    width: "9rem",
                                                    borderRadius: "1rem",
                                                    backgroundColor: backgroundColor
                                                }}>
                                                <ListItemButton
                                                    selected={selected}
                                                    disabled={selected}
                                                    onClick={() => setSelectedLicense(license)}
                                                    sx={{ width: "inherit", height: "inherit", borderRadius: "inherit" }}
                                                >
                                                    <ListItemIcon sx={{ width: "100%", height: "100%" }}>
                                                        {licenseData.icon}
                                                    </ListItemIcon>
                                                </ListItemButton>
                                            </Paper>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    textAlign: "center",
                                                    fontSize: '14px',
                                                    fontWeight: "medium"
                                                }}
                                                primary={licenseData.title}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>

                            {selectedLicense !== AdobeLicense.None && <Box sx={{ width: "100%", p: "1rem" }}>
                                <DesktopDatePicker
                                    label="Expiration Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={expirationDate}
                                    minDate={today}
                                    maxDate={thirtyDaysLater}
                                    onChange={(expDate: Date | null) => setExpirationDate(expDate)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                            }
                            <TextField
                                InputProps={{ style: { fontSize: 16 } }}
                                multiline rows={3}
                                placeholder="Reason"
                                sx={{ width: "100%", p: "1rem" }}
                                value={reason}
                                onChange={(e) => { setReason(e.target.value) }}
                            />
                        </Fragment>
                    )
                    }

                </DialogContent>
                <DialogActions>
                    <Button disabled={mutation.isLoading === true} onClick={props.handleClose}>Close</Button>
                    <Button disabled={submitted === true} onClick={saveLicense}>Save</Button>
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    )
}

export default LicenseModificationDialog
