import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    Link,
    Paper,
    Table,
    TableBody,
    TableContainer,
    Typography,
} from "@mui/material"
import { green, red } from "@mui/material/colors"
import { FactCheck, Functions, DoDisturb } from "@mui/icons-material"
import { Fragment, useState } from "react"

import AdobeLicenseManagementLog from "./AdobeLicenseManagementLog"
import LicenseModificationDialog from "./AdobeLicenseModificationDialog"
import ModelerLicenseModificationDialog from "./ModelerLicenseModificationDialog"
import useLicenseInfo from "../DataHooks/UseLicenseInfo"
import TableKeyValueRow from "../GenericBlocks/TableKeyValueRow"
import InfoBlock from "../GenericBlocks/InfoBlock/InfoBlock"
import HelpTooltip from "../GenericBlocks/HelpTooltip"
import CloseableAlert from "../PageStructure/AlertManager/CloseableAlert"
import { getLicenseData } from "./LicenseDescription"
import { AdobeLicense } from "../../apiInterfaces"

interface LicenseManagementProps {
    ucinetid: string
    expanded?: boolean
}

let LicenseManagement = (props: LicenseManagementProps) => {
    let { ucinetid, expanded = false } = props
    let [{ adobe, adobeLoading, adobeError, modeler, modelerLoading, modelerError }] =
        useLicenseInfo(ucinetid)
    let [showEnrollmentQtr, setShowEnrollmentQtr] = useState(false)
    let licenseData = getLicenseData(adobe?.license_type || AdobeLicense.None)

    //Adobe dialog
    let [adobeDialogOpen, setAdobeDialogOpen] = useState(false)
    let [adobeLogOpen, setAdobeLogOpen] = useState(false)

    //modeler dialog

    let [modelerDialogOpen, setModelerDialogOpen] = useState(false)

    if (modeler?.license_info) {
        if (modeler.license_info.expiration_date) {
            modeler.license_info.expiration_date = new Date(
                modeler.license_info.expiration_date
            ).toLocaleDateString("en-US")
        }
        if (modeler.license_info.assignment_time) {
            modeler.license_info.assignment_time = new Date(
                modeler.license_info.assignment_time
            ).toLocaleString("en-US")
        }
    }

    return (
        <InfoBlock
            title="License Management"
            icon={<FactCheck></FactCheck>}
            loading={adobeLoading || modelerLoading}
            expanded={expanded}
        >
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="Adobe License">
                                {adobeError || !adobe ? (
                                    <Alert severity="error">
                                        Something went wrong, please try again or contact dev team.
                                    </Alert>
                                ) : (
                                    <Card sx={{ minWidth: "40rem", p: "0.5rem" }}>
                                        {adobe.eligibility_result.eligible === false &&
                                            adobe.license_type !== AdobeLicense.None && (
                                                <CloseableAlert
                                                    severity="warning"
                                                    title="Ineligiblity Warning"
                                                >
                                                    This user is ineligible for an Adobe license but
                                                    is still holding one.
                                                </CloseableAlert>
                                            )}
                                        <CardHeader
                                            avatar={
                                                <Avatar sx={{ width: "3em", height: "3em" }}>
                                                    {" "}
                                                    {licenseData?.icon}
                                                </Avatar>
                                            }
                                            title={
                                                <Typography variant="h6" color="primary">
                                                    {licenseData?.title}
                                                </Typography>
                                            }
                                            subheader={
                                                <Fragment>
                                                    <Typography
                                                        fontWeight="medium"
                                                        color={
                                                            adobe?.eligibility_result.eligible
                                                                ? green[600]
                                                                : red[600]
                                                        }
                                                    >
                                                        The UCInetID {ucinetid.toUpperCase()} is
                                                        {adobe.eligibility_result.eligible
                                                            ? " eligible "
                                                            : " ineligible "}
                                                        for an Adobe License.{" "}
                                                    </Typography>
                                                    <Typography>
                                                        Reason:{" "}
                                                        {adobe.eligibility_result.reason_name}
                                                        <HelpTooltip
                                                            tooltip={
                                                                adobe.eligibility_result
                                                                    .reason_detail
                                                            }
                                                        />
                                                        {". "}
                                                        {adobe.enrolled_terms.length > 0 && (
                                                            <Link
                                                                component="button"
                                                                fontSize="1rem"
                                                                onClick={() =>
                                                                    setShowEnrollmentQtr(
                                                                        !showEnrollmentQtr
                                                                    )
                                                                }
                                                            >
                                                                {showEnrollmentQtr
                                                                    ? "Hide enrolled terms"
                                                                    : "Show enrolled terms"}
                                                            </Link>
                                                        )}
                                                    </Typography>
                                                    <Box>
                                                        {showEnrollmentQtr &&
                                                            adobe.enrolled_terms.map((term) => (
                                                                <Chip
                                                                    size="small"
                                                                    key={term}
                                                                    label={term}
                                                                    sx={{ m: "0.2rem" }}
                                                                />
                                                            ))}
                                                    </Box>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: "0.5rem" }}
                                                        onClick={() => setAdobeDialogOpen(true)}
                                                    >
                                                        Modify
                                                    </Button>
                                                    <LicenseModificationDialog
                                                        ucinetid={props.ucinetid}
                                                        open={adobeDialogOpen}
                                                        handleClose={() =>
                                                            setAdobeDialogOpen(false)
                                                        }
                                                        currentLicense={adobe.license_type}
                                                    />
                                                    <Link
                                                        component="button"
                                                        fontSize="1rem"
                                                        sx={{
                                                            ml: "1rem",
                                                            mt: "0.5rem",
                                                            verticalAlign: "sub",
                                                        }}
                                                        onClick={() => setAdobeLogOpen(true)}
                                                    >
                                                        Show Log
                                                    </Link>
                                                    <AdobeLicenseManagementLog
                                                        ucinetid={props.ucinetid}
                                                        open={adobeLogOpen}
                                                        handleClose={() => setAdobeLogOpen(false)}
                                                    />
                                                </Fragment>
                                            }
                                        />
                                    </Card>
                                )}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="SPSS Modeler license">
                                {modelerError || !modeler ? (
                                    <Alert severity="error">
                                        Something went wrong, please try again or contact dev team.
                                    </Alert>
                                ) : (
                                    <Card sx={{ minWidth: "40rem", p: "0.5rem" }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar sx={{ width: "3em", height: "3em" }}>
                                                    {modeler?.license_info ? (
                                                        <Functions fontSize="large" />
                                                    ) : (
                                                        <DoDisturb
                                                            fontSize="large"
                                                            sx={{
                                                                width: "inherit",
                                                                height: "inherit",
                                                                padding: "1rem",
                                                            }}
                                                        />
                                                    )}
                                                </Avatar>
                                            }
                                            title={
                                                <Typography variant="h6" color="primary">
                                                    {modeler?.license_info
                                                        ? "IBM SPSS Modeler"
                                                        : "No License"}
                                                </Typography>
                                            }
                                            subheader={
                                                <Fragment>
                                                    <Typography
                                                        fontWeight="medium"
                                                        color={
                                                            modeler?.eligibility_result
                                                                ? green[600]
                                                                : red[600]
                                                        }
                                                    >
                                                        The UCInetID {ucinetid.toUpperCase()} is
                                                        {modeler?.eligibility_result
                                                            ? " eligible "
                                                            : " ineligible "}
                                                        for an Modeler License.
                                                    </Typography>
                                                    {modeler?.license_info && (
                                                        <Fragment>
                                                            <Typography>
                                                                Assigned By:{" "}
                                                                {modeler.license_info.assigned_by}{" "}
                                                                at{" "}
                                                                {
                                                                    modeler.license_info
                                                                        .assignment_time
                                                                }
                                                            </Typography>
                                                            <Typography>
                                                                Reason:{" "}
                                                                {modeler.license_info
                                                                    .assignment_reason ?? "Unknown"}
                                                            </Typography>
                                                            <Typography>
                                                                Expiration:{" "}
                                                                {modeler.license_info
                                                                    .expiration_date ?? "Unknown"}
                                                            </Typography>
                                                        </Fragment>
                                                    )}
                                                    {modeler?.license_info ? (
                                                        <Button
                                                            disabled
                                                            variant="contained"
                                                            sx={{ mt: "0.5rem" }}
                                                            onClick={() =>
                                                                setModelerDialogOpen(true)
                                                            }
                                                        >
                                                            The user already has an active license
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            sx={{ mt: "0.5rem" }}
                                                            onClick={() =>
                                                                setModelerDialogOpen(true)
                                                            }
                                                        >
                                                            Assign
                                                        </Button>
                                                    )}
                                                    <ModelerLicenseModificationDialog
                                                        ucinetid={props.ucinetid}
                                                        open={modelerDialogOpen}
                                                        handleClose={() =>
                                                            setModelerDialogOpen(false)
                                                        }
                                                    />
                                                </Fragment>
                                            }
                                        />
                                    </Card>
                                )}
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default LicenseManagement
