import { BlockListRecord } from "../../apiInterfaces"
import _ from "lodash"
import { useQueries, useQuery, UseQueryResult } from "react-query"
import { fetchBlockList } from "../../apiFunctions"

interface BlockListData {
    loading: boolean
    ucinetidBlocked: boolean
    blocks: BlockListRecord[]
}

let add_colons_to_mac_address = (mac_address: string) => {
    mac_address = _.replace(mac_address, ":", "")
    let mac_match = /[\w]{2}/g
    let groups = Array.from(mac_address.matchAll(mac_match), (m) => m[0])

    if (groups !== null) {
        return groups.join(":")
    } else {
        return mac_address
    }
}

let useBlockList = (
    ucinetid: string,
    mac_addresses: string[]
): BlockListData => {
    let { isLoading: ucinetid_loading, data: ucinetid_blocks } = useQuery(
        ["blocked-list", ucinetid],
        fetchBlockList(ucinetid)
    )
    let mac_address_blocks = (useQueries(
        mac_addresses.map((m) => {
            let mac = add_colons_to_mac_address(m)

            return {
                queryKey: ["blocked-list", mac],
                queryFn: fetchBlockList(mac),
            }
        })
    ) as any) as UseQueryResult<BlockListRecord[], unknown>[]

    return {
        loading:
            ucinetid_loading ||
            mac_address_blocks.filter((m) => m.isLoading).length > 0,
        ucinetidBlocked: (ucinetid_blocks?.length ?? 0) > 0,
        blocks: [
            ...(ucinetid_blocks ?? []),
            ...(mac_address_blocks
                .map((m) => m.data)
                .flat()
                .filter((m) => m !== undefined) as BlockListRecord[]),
        ],
    }
}

export default useBlockList
