import { Box, Typography } from "@mui/material"
import JPL from "../NetworkInformation/JPL/JPL"
import PageParent from "../PageStructure/PageParent/PageParent"
import ToolHeader from "../GenericBlocks/ToolHeader"
import { NetworkSetupAssistant } from "./NetworkSetupAssistant/NetworkSetupAssistant"

interface NetworkProps {}

let Network = (props: NetworkProps) => {
    return (
        <PageParent>
            <ToolHeader>Network</ToolHeader>
            <Box paddingBottom="2rem">
                <Typography gutterBottom>
                    A series of tools that can help diagnose more complex network problems. These
                    network tools are designed to provide a more detailed view of UCI's network
                    infrastructure. If you can think of ways to integrate this data into the search
                    tool please let us know!
                </Typography>
            </Box>
            <JPL></JPL>
            <NetworkSetupAssistant></NetworkSetupAssistant>
        </PageParent>
    )
}

export default Network
