import { Box} from "@mui/material"
import React from "react"
import { Routes, Route } from "react-router-dom"
import { Route as HDURoute } from "../../../routes"
import ErrorBoundary from "../../ErrorManagement/ErrorBoundary"
import NotFoundPage from "../NotFoundPage/NotFoundPage"

interface ComponentViewerProps {
    routes: HDURoute[]
}

let ComponentViewer = (props: ComponentViewerProps) => {
    let { routes: propRoutes } = props

    let [routes, setRoutes] = React.useState<HDURoute[]>([])

    React.useEffect(() => {
        let rs = [...propRoutes]
        rs.sort((a, b) => b.path.length - a.path.length)
        setRoutes(rs)
    }, [propRoutes])

    return (
        <ErrorBoundary>
            <Box display="flex" flexDirection="column" height="100%">
                <Routes>
                    {routes.map(({ path, component: Component }) => (
                        <Route key={path} path={path} element={<Component />}></Route>
                    ))}
                    <Route path="*" element={<NotFoundPage />}></Route>
                </Routes>
            </Box>
        </ErrorBoundary>
    )
}

export default ComponentViewer
