import { Toolbar } from "@mui/material"
import { routes, admin_routes } from "../../../routes"
import ComponentViewer from "../ComponentViewer/ComponentViewer"
import FeedbackButton from "../Feedback/FeedbackButton"
import SidebarList from "../SidebarList/SidebarList"
import UtilityNavbar from "../../UtilityNavbar/UtilityNavbar"
import { useCurrentUserInfo } from "../../DataHooks/useCurrentUserInfo"
import { Box } from "@mui/system"

interface RouteControllerProps {
    toggleDarkMode: () => void
}

function RouteController(props: RouteControllerProps) {
    let { is_admin_user } = useCurrentUserInfo()

    const all_routes = is_admin_user ? [...routes, ...admin_routes] : routes

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <UtilityNavbar toggleDarkMode={props.toggleDarkMode} />
            <SidebarList routes={all_routes.filter((route) => route.hideOnSideBar !== true)} />
            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, overflow: "auto" }}>
                <Toolbar />
                <Box sx={{ flexGrow: 1, padding: "3rem 3rem 0rem 3rem", overflow: "auto" }}>
                    <ComponentViewer routes={all_routes} />
                </Box>
            </Box>
            <FeedbackButton />
        </Box>
    )
}

export default RouteController
