import React from "react"
import { Paper, Table, TableBody, List, ListItem } from "@mui/material"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import { Check, Close, School } from "@mui/icons-material"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import useLDAP from "../../DataHooks/useLDAP"

interface StudentInfoProps {
    ucinetid: string
    expanded?: boolean
}

let StudentInfo = (props: StudentInfoProps) => {
    let { ucinetid, expanded = false } = props

    let { loading, entry } = useLDAP(ucinetid)

    let {
        uciAffiliation,
        uciStudentID,
        uciStudentLevel,
        uciStudentMajor,
        uciStudentGivenName,
        uciStudentMiddleName,
        uciStudentSN,
        uciStudentEmailRelease,
        // uciAlumniMailAlias,
        // uciAlumniMailDeliveryPoint,
        uciApplicantEmail,
    } = entry?.attributes ?? {}

    let has_student_affiliation =
        loading ||
        uciAffiliation?.includes("student") ||
        uciAffiliation?.includes("applicant") ||
        uciAffiliation?.includes("former_student")

    return (
        <InfoBlock
            title="Student Info"
            icon={<School></School>}
            loading={loading}
            expanded={expanded}
            hide={!has_student_affiliation}
        >
            <Paper>
                <Table>
                    <TableBody>
                        <TableKeyValueRow title="Student Full Name">
                            {[uciStudentGivenName, uciStudentMiddleName, uciStudentSN].join(" ")}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title={
                                uciStudentID && uciStudentID.length > 1
                                    ? "Student IDs"
                                    : "Student ID"
                            }
                        >
                            <List dense disablePadding>
                                {uciStudentID?.map((id) => (
                                    <ListItem key={id} disableGutters>
                                        {id}
                                    </ListItem>
                                ))}
                            </List>
                        </TableKeyValueRow>
                        <TableKeyValueRow title="Grade Level">{uciStudentLevel}</TableKeyValueRow>
                        <TableKeyValueRow title="Major">{uciStudentMajor}</TableKeyValueRow>
                        <TableKeyValueRow
                            title="Student Email Published?"
                            tooltip="Whether or not the student's email can be published."
                        >
                            {uciStudentEmailRelease ? <Check></Check> : <Close></Close>}
                        </TableKeyValueRow>
                        <TableKeyValueRow title="Applicant Email">
                            {uciApplicantEmail}
                        </TableKeyValueRow>
                        {/* <TableKeyValueRow title="Alumni Alias">
                            {uciAlumniMailAlias}
                        </TableKeyValueRow>
                        <TableKeyValueRow title="Alumni Email MDP">
                            {uciAlumniMailDeliveryPoint}
                        </TableKeyValueRow> */}
                    </TableBody>
                </Table>
            </Paper>
        </InfoBlock>
    )
}

export default StudentInfo
