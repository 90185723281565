import React from "react"
import { ForescoutFilteredHost } from "../../../../apiInterfaces"
import { calculate_bolded_sections } from "./useMACAddressSearchSuggestions"
import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import { SettingsEthernet } from "@mui/icons-material"
import { green, red } from "@mui/material/colors"
import { Skeleton } from '@mui/material';
import { RESNET_SUBNET_TO_SEGMENT } from "../../../../data/resnet_segments"
import { useQuery } from "react-query"
import { fetchForescoutHostByMAC } from "../../../../apiFunctions"

interface ForescoutSearchSuggestionProps {
    forescout_info: ForescoutFilteredHost
    searchValue: string
}

let ForescoutSearchSuggestion = (props: ForescoutSearchSuggestionProps) => {
    let { forescout_info, searchValue } = props

    let mac_address = forescout_info.mac

    let { first_part, bolded, last_part } = calculate_bolded_sections(mac_address, searchValue)

    let { isLoading, data: forescout_host } = useQuery(
        ["forescout-host-by-mac", mac_address],
        fetchForescoutHostByMAC(mac_address),
        { enabled: Boolean(mac_address) }
    )

    let forescout_community = React.useMemo(() => {
        let bdomain = forescout_host?.host?.fields?.bdomain
            ?.value as keyof typeof RESNET_SUBNET_TO_SEGMENT

        return RESNET_SUBNET_TO_SEGMENT[bdomain ?? ""]
    }, [forescout_host])

    let online = forescout_host?.host?.fields?.online?.value ?? ""
    let registered_user = forescout_host?.host?.fields?.dex_MobileAccessUser?.value ?? ""
    let hostname = forescout_host?.host?.fields?.dhcp_hostname_v2?.value ?? ""
    let vendor = forescout_host?.host?.fields?.mac_vendor_string?.value ?? ""

    return (
        <ListItem button>
            <ListItemAvatar>
                <Avatar
                    style={{
                        backgroundColor:
                            forescout_host?.host?.fields?.online?.value === "true"
                                ? green["A400"]
                                : red["A400"],
                    }}
                >
                    <SettingsEthernet></SettingsEthernet>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                disableTypography
                primary={
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="body1">
                                {first_part}
                                <b>{bolded}</b>
                                {last_part}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body1">
                                Registered To:{" "}
                                {registered_user !== "" ? registered_user : "Unregistered"}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                secondary={
                    isLoading ? (
                        <>
                            <Skeleton></Skeleton>
                            <Skeleton></Skeleton>
                        </>
                    ) : (
                        <Grid container>
                            <Grid container item>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body2" color="textSecondary">
                                        {online === "true" ? "Online" : "Offline"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body2" color="textSecondary">
                                        Hostname: {hostname !== "" ? hostname : "Unknown"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body2" color="textSecondary">
                                        {forescout_community}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="body2" color="textSecondary">
                                        Vendor: {vendor !== "" ? vendor : "Unknown"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="textSecondary">
                                    Forescout Network Host
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }
            ></ListItemText>
        </ListItem>
    )
}

export default ForescoutSearchSuggestion
