export const ComponentKeys = [
    "quick-info",
    "basic-user-info",
    "employee-info",
    "student-info",
    "sponsor-info",
    "active-directory-info",
    "department-support",
    "ksams-info",
    "license-management",
    "network-registrations",
    "block-list",
] as const

export type ComponentKey = typeof ComponentKeys[number]

export const COMPONENT_DISPLAY_NAME = {
    "quick-info": "Quick Info",
    "basic-user-info": "Basic User Info",
    "employee-info": "Employee Info",
    "student-info": "Student Info",
    "sponsor-info": "Sponsor Info",
    "active-directory-info": "Active Directory",
    "department-support": "Department Support",
    "ksams-info": "KSAMS",
    "license-management": "License Management",
    "network-registrations": "Network Registrations",
    "block-list": "Block List",
}
