import React from "react"
import { Fade } from "@mui/material"
import Venn from "./Venn"
import { Box } from "@mui/system"

let NotFoundPage = (props) => {
    return (
        <Fade timeout={5000} in={true}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Venn />
            </Box>
        </Fade>
    )
}

export default NotFoundPage
