import { TableCell, TableRow, Button, Collapse, Typography } from "@mui/material"
import React from "react"
import { escape_text } from "./JPL"
import { JPLPort as JPLPortInterface } from "../../../apiInterfaces"
import JPLSubnet from "./JPLSubnet"
import { Skeleton } from "@mui/material"
import { useQuery } from "react-query"
import { fetchSubnetInformation } from "../../../apiFunctions"

interface JPLPortProps {
    port: JPLPortInterface
}

let JPLPort = (props: JPLPortProps) => {
    let { port } = props

    let [showSubnetInfo, setShowSubnetInfo] = React.useState(false)

    let { isLoading, data } = useQuery(
        ["jpl-subnet", port.subnet],
        fetchSubnetInformation(port.subnet),
        {
            enabled: showSubnetInfo,
        }
    )

    let handleShowSubnet = () => {
        setShowSubnetInfo(!showSubnetInfo)
    }

    return (
        <>
            <TableRow>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{port.station_id}</TableCell>
                <TableCell>{port.building}</TableCell>
                <TableCell>{port.room ? port.room : "N/A"}</TableCell>
                <TableCell>
                    {port.subnet ? <Button onClick={handleShowSubnet}>{port.subnet}</Button> : null}
                </TableCell>
                <TableCell>{port.port}</TableCell>
                <TableCell>{port.voip_phone_no}</TableCell>
                <TableCell>{port.contact}</TableCell>
                <TableCell>{escape_text(port.notes)}</TableCell>
            </TableRow>
            {showSubnetInfo && (
                <>
                    {isLoading && (
                        <TableRow>
                            <TableCell colSpan={8}>
                                <Skeleton variant="rectangular" height="5rem"></Skeleton>
                            </TableCell>
                        </TableRow>
                    )}
                    {!isLoading &&
                        ((data?.length ?? 0) > 0 ? (
                            data?.map((subnet) => (
                                <TableRow
                                    key={`${subnet.jpl_network_name}.${subnet.building}.${subnet.interface}`}
                                    selected
                                >
                                    <TableCell colSpan={8}>
                                        <Collapse in={true} appear>
                                            <JPLSubnet subnet={subnet}></JPLSubnet>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow selected>
                                <TableCell colSpan={8}>
                                    <Collapse in={true} appear>
                                        <Typography>
                                            Could not find a matching JPL Network for "
                                            <b>{port.subnet}</b>"
                                        </Typography>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        ))}
                </>
            )}
        </>
    )
}

export default JPLPort
