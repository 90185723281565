import { Collapse } from "@mui/material"
import { Alert, AlertTitle } from '@mui/material';
import { AlertProps } from '@mui/lab';
import React from "react"

interface CloseableAlertProps extends AlertProps {
    hidden?: boolean
    title?: string
    children: React.ReactNode
    [key: string]: any
}

let CloseableAlert = (props: CloseableAlertProps) => {
    let { title, children, hidden = false, ...otherProps } = props
    let [hide, setHide] = React.useState(false)

    return (
        <Collapse in={!hidden && !hide} appear>
            <Alert
                onClose={() => {
                    setHide(true)
                }}
                {...otherProps}
                sx={{
                    marginTop: "0.5rem", 
                    marginBottom: "0.5rem"
                }}
            >
                {title && (
                    <AlertTitle>
                        <b>{title}</b>
                    </AlertTitle>
                )}
                {children}
            </Alert>
        </Collapse>
    )
}

export default CloseableAlert
