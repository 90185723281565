import { AppBar, Grid, IconButton, Link, Toolbar, Typography } from "@mui/material"
import { NightsStay } from "@mui/icons-material"
import { Link as RouteLink } from "react-router-dom"
import Notifications from "./Notifications"

interface UtilityNavbarProps {
    toggleDarkMode: () => void
}

const UtilityNavbar = (props: UtilityNavbarProps) => {
    let { toggleDarkMode } = props

    return (
        <AppBar color="transparent" sx={{ backgroundColor: "background.default" }}>
            <Toolbar>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" noWrap sx={{ fontWeight: "bold" }}>
                            <Link component={RouteLink} color="inherit" underline="none" to="/">
                                Help Desk Utilities
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Notifications></Notifications>
                        <IconButton onClick={toggleDarkMode} size="large">
                            <NightsStay fontSize="small"></NightsStay>
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default UtilityNavbar
