import { Paper, Table, TableContainer } from "@mui/material"
import { WifiTethering } from "@mui/icons-material"
import React from "react"
import { VisitorNetworkRegistration } from "../../../apiInterfaces"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import { format_date_string } from "../../Utilities/dateUtilities"

interface VisitorNetworkRegistrationsProps {
    loading: boolean
    expanded?: boolean
    registrations: VisitorNetworkRegistration[]
}

let VisitorNetworkRegistrations = (props: VisitorNetworkRegistrationsProps) => {
    let { loading, expanded = false, registrations } = props

    let add_mac_separators = (mac_address: string) => {
        let groups = mac_address.match(/../g)
        return groups !== null ? groups.join(":") : mac_address
    }

    return (
        <InfoBlock
            title="Visitor Network Registrations"
            icon={<WifiTethering></WifiTethering>}
            loading={loading}
            expanded={expanded}
            hide={!loading && registrations.length === 0}
        >
            <Paper>
                <TableContainer>
                    <Table>
                        {registrations.map((reg) => (
                            <React.Fragment key={reg.known_visitors_id}>
                                <TableKeyValueRow title="MAC Address">
                                    {add_mac_separators(reg.mac_address).toUpperCase()}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Created">
                                    {format_date_string(reg.created_DTS)}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Expires">
                                    {format_date_string(reg.expires)}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Name">{reg.name}</TableKeyValueRow>
                                <TableKeyValueRow title="Email">
                                    {reg.email_address}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Reason">{reg.reason}</TableKeyValueRow>
                                <TableKeyValueRow title="Reason (Other)">
                                    {reg.reason_other}
                                </TableKeyValueRow>
                            </React.Fragment>
                        ))}
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default VisitorNetworkRegistrations
