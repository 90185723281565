import { Container, Tab, useTheme, } from "@mui/material"
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useState } from "react"
import PageParent from "../PageStructure/PageParent/PageParent"
import LicenseManagerLogs from "../LicenseManagement/LicenseManagerLogs"
import DepartmentSupportAdmin from "../DepartmentSupportAdmin/DepartmentSupportAdmin"


let Administration = () => {
    let [value, setValue] = useState('1')

    const theme = useTheme()
    const handleChange = (event: React.SyntheticEvent, tabValue: string) => {
        setValue(tabValue)
    }

    return (
        <PageParent fullHeight minWidth="60rem">
            <TabContext value={value}>
                <Container fixed sx={{
                    top: "4rem",
                    pt: "1rem",
                    position: "fixed",
                    zIndex: "999",
                    backgroundColor: theme.palette.mode === "dark" ? "rgba(20, 20, 20, 0.95)" : "rgba(255, 255, 255, 0.95)"
                }}>
                    <TabList onChange={handleChange} centered variant="fullWidth">
                        <Tab value="0" label="Department Support Admin" />
                        <Tab value="1" label="License Manager Logs" />
                    </TabList >
                </Container>
                <TabPanel value="0" sx={{ height: "inherit" }}>
                    <DepartmentSupportAdmin/>
                </TabPanel>
                <TabPanel value="1" sx={{ height: "inherit" }}>
                    <LicenseManagerLogs/>
                </TabPanel>
            </TabContext>
        </PageParent>
    )
}

export default Administration