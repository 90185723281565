import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material"
import { format_date_string } from "../../Utilities/dateUtilities"
import { Skeleton } from '@mui/material';
import { AccessTime, TableChart } from "@mui/icons-material"
import { useQuery } from "react-query"
import { fetchSubnetTableMetadata } from "../../../apiFunctions"

let SubnetsMetadata = () => {
    let { isLoading, data: subnetsMeta } = useQuery(
        "subnet-table-metadata",
        fetchSubnetTableMetadata
    )

    return (
        <>
            <Typography variant="h6">Subnets Database:</Typography>
            <Paper>
                <Box padding="1rem">
                    <Typography>
                        The subnets database is still in Beta as we experiment
                        with different ways to parse the UCInet-GW's data. To
                        view subnet information for a port, click on the port's{" "}
                        subnet in the "Subnet" column. There might be more than
                        one potential subnet because the JPL system can use
                        similar names. Check the Building ID for a subnet to
                        ensure you're using the right one!
                        <br></br>
                        <br></br>
                        Below is information on when the database was last
                        updated:
                    </Typography>
                    <List dense>
                        <ListItem>
                            <ListItemIcon>
                                <TableChart></TableChart>
                            </ListItemIcon>
                            <ListItemText>
                                Spreadsheet Name:{" "}
                                {isLoading ? (
                                    <Skeleton
                                        width="30%"
                                        style={{ display: "inline-block" }}
                                    ></Skeleton>
                                ) : (
                                    subnetsMeta?.data_set_name
                                )}
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AccessTime></AccessTime>
                            </ListItemIcon>
                            <ListItemText>
                                Last Updated:{" "}
                                {isLoading ? (
                                    <Skeleton
                                        width="40%"
                                        style={{ display: "inline-block" }}
                                    ></Skeleton>
                                ) : (
                                    subnetsMeta &&
                                    format_date_string(
                                        subnetsMeta?.last_updated
                                    )
                                )}
                            </ListItemText>
                        </ListItem>
                    </List>
                </Box>
            </Paper>
        </>
    )
}

export default SubnetsMetadata
